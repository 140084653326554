import { UserModel } from '../models/user-model.model';
import { AuthActionTypes, AuthActionUnion } from './auth.actions';
import { State } from '../../../state';
import { AwardItem, PaymentItem } from '../../profile/models/profile.model';

export interface AuthState {
	isLogged: boolean;
	isLoading: boolean;
	user: UserModel;
	bankHistory: PaymentItem[];
	firstAuth: boolean;
}

export const initialState: AuthState = {
	firstAuth: false,
	isLoading: false,
	isLogged: false,
	bankHistory: [],
	user: null,
};
export function reducer(
	state = initialState,
	action: AuthActionUnion
): AuthState {
	switch (action.type) {
		case AuthActionTypes.DONATE:
			return {
				...state,
				user: {
					...state.user,
					allBank: state.user.allBank + action.payload.score,
				},
			};
		case AuthActionTypes.REGISTRATION:
		case AuthActionTypes.LOG_IN:
			return {
				...state,
				isLoading: true,
			};
		case AuthActionTypes.REGISTRATION_SUCCESS:
		case AuthActionTypes.LOG_IN_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLogged: true,
			};
		case AuthActionTypes.LOG_IN_FAILURE:
		case AuthActionTypes.REGISTRATION_FAILURE:
			return {
				...state,
				isLoading: false,
				isLogged: false,
			};
		case AuthActionTypes.GET_BANK_HISTORY:
			return {
				...state,
				isLoading: true,
			};
		case AuthActionTypes.GET_BANK_HISTORY_SUCCESS:
			return {
				...state,
				isLoading: false,
				bankHistory: action.payload,
			};
		case AuthActionTypes.LOG_OUT:
			return {
				...state,
				user: null,
				isLogged: false,
			};
		case AuthActionTypes.LOG_OUT_SUCCESS:
			return {
				...state,
				isLogged: false,
				isLoading: false,
				user: null,
			};
		case AuthActionTypes.GET_USER:
			return {
				...state,
				isLoading: true,
			};
		case AuthActionTypes.UPDATE_USER:
			return {
				...state,
				isLoading: true,
			};
		case AuthActionTypes.GET_USER_SUCCESS:
			return {
				...state,
				isLogged: true,
				isLoading: false,
				user: action.payload,
			};
		default:
			return state;
	}
}

export const selectUserID = (state: State): number => state.auth?.user?.userID;
export const selectUser = (state: State): UserModel => state.auth?.user;
export const selectAllBank = (state: State): number => state.game?.allBank ?? 0;
export const selectIsLogged = (state: State): boolean => state.auth?.isLogged;
export const selectBankHistory = (state: State): PaymentItem[] =>
	state.auth.bankHistory;
export const selectAwards = (state: State): AwardItem[] =>
	state.auth?.user?.awards;
