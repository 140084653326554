export const environment = {
	production: false,
	apiEndpoint: 'https://dobrofura.igm.gg/api/v1',
	publicIdForWidget: 'pk_d4da563238c9caa9ed101937fe060',
	wsEndpoint: 'wss://dobrofura.igm.gg/api/v1/ws',
	wsNoAuthEndpoint: 'wss://dobrofura.igm.gg/api/v1/ws-no-auth',
	redirectVkAuth: 'https://dobrofura.igm.gg/west-wagon',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
