import {
	Component,
	ElementRef,
	Input,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { CitiesNames } from '../../../../../assets/cities.data';

@Component({
	selector: 'app-east-path',
	templateUrl: './east-path.component.html',
	styleUrls: [
		'./east-path.component.scss',
		'../west-path/west-path.component.scss',
		'../hero-container/hero-container.component.scss',
	],
})
export class EastPathComponent {
	@Input() eventId: number;
	protected readonly CitiesNames = CitiesNames;
	@ViewChild('tooltip') tooltip: ElementRef;
	@ViewChild('parent') parent: ElementRef;

	constructor(private renderer: Renderer2) {}
	public mouseEnter($event, city: CitiesNames, date: string): void {
		const parentRect = this.parent.nativeElement.getBoundingClientRect();
		const childRect = ($event.target as HTMLElement).getBoundingClientRect();

		const relativeX = childRect.left - parentRect.left;
		const relativeY = childRect.top - parentRect.top;
		const x = `${relativeX + 5}px`;
		const y = `${relativeY - 30}px`;
		this.renderer.setStyle(this.tooltip.nativeElement, 'left', x);
		this.renderer.setStyle(this.tooltip.nativeElement, 'top', y);
		this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'block');
		this.renderer.setProperty(
			this.tooltip.nativeElement,
			'innerHTML',
			`${city}<br>${date}`
		);
	}

	public mouseLeave(): void {
		this.renderer.setProperty(this.tooltip.nativeElement, 'innerHTML', '');
		this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'none');
	}
}
