import { Component, Input } from '@angular/core';
import { UserModel } from '../../../auth/models/user-model.model';
import { FundData, Raffle, RaffleWinner } from '../../models/game.model';

@Component({
	selector: 'app-wagon-screen',
	templateUrl: './wagon-screen.component.html',
	styleUrls: ['./wagon-screen.component.scss'],
})
export class WagonScreenComponent {
	@Input() user: UserModel;
	@Input() funds: FundData[];
	@Input() raffles: Raffle[];
	@Input() winners: RaffleWinner[];
	@Input() activeRoute: 'east' | 'west';
}
