import { CityDescriptionData } from '../app/modules/shared/components/card-city-description/card-city-description.component';
import { DateTime } from 'luxon';

export const citiesData: CityDescriptionData[] = [
	{
		name: '',
		stadium: '',
		date: '',
		time: '',
	},
];

export interface EventObj {
	date: Date;
	name: string;
	id: number;
	cityImage: string;
	active: boolean;
}

export enum CitiesNames {
	SOCHI = 'Сочи',
	KAZAN = 'Казань',
	NIZHNEKAMSK = 'Нижнекамск',
	CHEREPOVEC = 'Череповец',
	JAROSLAVL = 'Ярославль',
	MOSCOW = 'Москва',
	BALASHIHA = 'Балашиха',
	NIZHNIY = 'Нижний-Новгород',
	SPB = 'Санкт-Петербург',
	UFA = 'Уфа',
	TOLJATI = 'Тольятти',
	EKATERINBURG = 'Екатеринбург',
	CHELABINSK = 'Челябинск',
	MAGNITOGORSK = 'Магнитогорск',
	OMSK = 'Омск',
	NOVOSIBIRSK = 'Новосибирск',
	VLADIVOSTOK = 'Владивосток',
	HABAROVSK = 'Хабаровск',
}

export const eventsWest: EventObj[] = [
	{
		id: 1,
		name: CitiesNames.SOCHI,
		active: false,
		date: DateTime.fromISO('2023-11-13').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 2,
		name: CitiesNames.KAZAN,
		active: false,
		date: DateTime.fromISO('2023-11-16').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 3,
		name: CitiesNames.NIZHNEKAMSK,
		active: false,
		date: DateTime.fromISO('2023-11-17').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 4,
		name: CitiesNames.CHEREPOVEC,
		active: false,
		date: DateTime.fromISO('2023-11-19').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 5,
		name: CitiesNames.JAROSLAVL,
		active: false,
		date: DateTime.fromISO('2023-11-21').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 6,
		name: CitiesNames.MOSCOW,
		active: false,
		date: DateTime.fromISO('2023-11-23').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 7,
		name: CitiesNames.BALASHIHA,
		active: false,
		date: DateTime.fromISO('2023-11-25').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 8,
		name: CitiesNames.MOSCOW,
		active: false,
		date: DateTime.fromISO('2023-11-27').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 9,
		name: CitiesNames.NIZHNIY,
		active: false,
		date: DateTime.fromISO('2023-11-29').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 10,
		name: CitiesNames.MOSCOW,
		active: false,
		date: DateTime.fromISO('2023-12-07').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 11,
		name: CitiesNames.SPB,
		active: false,
		date: DateTime.fromISO('2023-12-08').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 13,
		name: CitiesNames.SPB,
		active: false,
		date: DateTime.fromISO('2023-12-19').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 14,
		name: CitiesNames.TOLJATI,
		active: false,
		date: DateTime.fromISO('2023-12-21').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 15,
		name: CitiesNames.UFA,
		active: false,
		date: DateTime.fromISO('2023-12-22').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
];
export const eventsEast: EventObj[] = [
	{
		id: 1,
		name: CitiesNames.NOVOSIBIRSK,
		active: false,
		date: DateTime.fromISO('2023-11-28').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 2,
		name: CitiesNames.OMSK,
		active: false,
		date: DateTime.fromISO('2023-12-01').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 3,
		name: CitiesNames.MAGNITOGORSK,
		active: false,
		date: DateTime.fromISO('2023-12-05').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 4,
		name: CitiesNames.CHELABINSK,
		active: false,
		date: DateTime.fromISO('2023-12-07').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 5,
		name: CitiesNames.SPB,
		active: false,
		date: DateTime.fromISO('2023-12-08').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 6,
		name: CitiesNames.EKATERINBURG,
		active: false,
		date: DateTime.fromISO('2023-12-21').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
	{
		id: 7,
		name: CitiesNames.UFA,
		active: false,
		date: DateTime.fromISO('2023-12-22').toJSDate(),
		cityImage: 'assets/images/cities/example.png',
	},
];
