import { Component, Input } from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import { Mousewheel, Navigation } from 'swiper/modules';

@Component({
	selector: 'app-moments-slider',
	templateUrl: './moments-slider.component.html',
	styleUrls: ['./moments-slider.component.scss'],
})
export class MomentsSliderComponent {
	fotos: string[] = [
		'assets/images/foto-slide-1.png',
		'assets/images/foto-slide-2.png',
		'assets/images/foto-slide-3.png',
		'assets/images/foto-slide-4.png',
		'assets/images/foto-slide-5.png',
		'assets/images/foto-slide-6.png',
		'assets/images/foto-slide-7.png',
		'assets/images/foto-slide-8.png',
		'assets/images/foto-slide-9.png',
		'assets/images/foto-slide-10.png',
		'assets/images/foto-slide-11.png',
		'assets/images/foto-slide-12.png',
	];
	public config: SwiperOptions = {
		modules: [Navigation],
		autoHeight: true,
		slidesPerView: 3,
		speed: 500,
		effect: 'coverflow',
		autoplay: {
			delay: 2000,
		},
		loop: true,
		preventInteractionOnTransition: true,
		allowTouchMove: true,
		navigation: {
			enabled: true,
			prevEl: '.moments__slider-prev',
			nextEl: '.moments__slider-next',
			disabledClass: '_disabled',
		},
	};
}
