import { Pipe, PipeTransform } from '@angular/core';
import { NewsImage } from '../models/game.model';
import { Image } from '@ks89/angular-modal-gallery';

@Pipe({
	name: 'convertToImage',
})
export class ConvertToImagePipe implements PipeTransform {
	transform(data: NewsImage[]): { original: NewsImage[]; gallery: Image[] } {
		const imagesRaw = [...data];
		imagesRaw.map((image, index) => new Image(index, { img: image.pic }));
		const gallery: Image[] = imagesRaw.map(
			(image, index) => new Image(index, { img: image.pic })
		);

		return { original: imagesRaw, gallery };
	}
}
