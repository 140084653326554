<div class="hero__way" #parent>
	<div class="path-tooltip" #tooltip></div>

	<div class="west-path-container">
		<svg
			class="west-path"
			width="728"
			height="167"
			viewBox="0 0 728 167"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M659.899 83.5034C659.073 83.5589 658.448 84.274 658.503 85.1005C658.559 85.9271 659.274 86.5522 660.101 86.4966L659.899 83.5034ZM663.306 86.3041C664.133 86.2605 664.768 85.5545 664.725 84.7273C664.681 83.9 663.975 83.2647 663.148 83.3082L663.306 86.3041ZM669.661 83.0749C668.833 83.0894 668.173 83.7725 668.187 84.6008C668.202 85.4291 668.885 86.0889 669.713 86.0745L669.661 83.0749ZM676.089 86.1041C676.917 86.1282 677.607 85.4764 677.631 84.6484C677.656 83.8203 677.004 83.1295 676.176 83.1054L676.089 86.1041ZM682.714 83.492C681.889 83.4156 681.159 84.0225 681.082 84.8474C681.006 85.6723 681.613 86.4029 682.438 86.4792L682.714 83.492ZM688.7 87.3163C689.516 87.4626 690.295 86.9202 690.441 86.1048C690.588 85.2894 690.045 84.5098 689.23 84.3635L688.7 87.3163ZM695.661 85.8586C694.865 85.6294 694.034 86.0889 693.804 86.885C693.575 87.6811 694.035 88.5123 694.831 88.7415L695.661 85.8586ZM700.838 90.7586C701.615 91.0472 702.478 90.6516 702.767 89.8751C703.056 89.0986 702.66 88.2351 701.884 87.9465L700.838 90.7586ZM707.898 90.1526C707.113 89.8871 706.262 90.308 705.996 91.0927C705.731 91.8775 706.152 92.7288 706.937 92.9944L707.898 90.1526ZM660.101 86.4966C661.2 86.4228 662.268 86.3588 663.306 86.3041L663.148 83.3082C662.095 83.3637 661.012 83.4286 659.899 83.5034L660.101 86.4966ZM669.713 86.0745C672.001 86.0346 674.119 86.0469 676.089 86.1041L676.176 83.1054C674.156 83.0467 671.991 83.0343 669.661 83.0749L669.713 86.0745ZM682.438 86.4792C684.751 86.6933 686.82 86.979 688.7 87.3163L689.23 84.3635C687.262 84.0104 685.109 83.7135 682.714 83.492L682.438 86.4792ZM694.831 88.7415C697.005 89.3675 698.948 90.056 700.838 90.7586L701.884 87.9465C699.965 87.2332 697.941 86.515 695.661 85.8586L694.831 88.7415ZM706.937 92.9944C707.951 93.3377 708.998 93.6671 710.098 93.9737L710.903 91.0839C709.865 90.7945 708.871 90.4819 707.898 90.1526L706.937 92.9944Z"
				fill="#E80024" />
			<path
				d="M594 96.0001C602.196 91.941 603.088 92.0001 617 89.5004C623.021 88.4186 639.178 85.6562 644.5 85"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M536 136C558.233 129.152 555.698 119.234 572.5 106.868M572.5 106.868C575.961 104.321 575.382 104.287 580 102L572.5 106.868Z"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M461 119.5C485.655 131.917 490.183 144.698 512 143.462M512 143.462L522 142C516.985 143.545 517.247 143.165 512 143.462Z"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M394.5 102C419.709 103.337 433.478 108.386 447.5 113.5"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M322 101.5C341.722 91.8058 368.547 97.5585 379.5 99.0001"
				stroke="#E80024"
				stroke-width="3" />
			<path
				d="M295.5 111.332C299.463 109.406 303.858 107.678 309 106.203L295.5 111.332ZM295.5 111.332C282.844 117.482 274.596 125.657 260.5 134.101"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M230 148.108C234.896 146.368 242.25 143.609 247 141.5L230 148.108ZM230 148.108C212.244 154.419 211.827 156.917 198.5 156.5"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M165.5 153.939C161.403 152.72 157.42 151.128 153.5 149.171M165.5 153.939L153.5 149.171M165.5 153.939C171.385 155.692 176.504 156.797 183 157M153.5 149.171C145.877 145.364 138.497 140.173 131 133.637"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M107 109.124C103.849 105.527 95.7505 91.4188 93 87.5L107 109.124ZM107 109.124C111.285 114.016 115.434 118.544 119.5 122.704"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M69.8597 44.5001C69.2809 43.7658 63.5 36.5 63.5 36.5C63.5 36.5 65.2437 37.4337 69.8597 44.5001ZM69.8597 44.5001C75.9281 53.7898 80.26 64.1526 85.0415 74.5"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M52.0776 27.6828C52.7308 28.1923 53.6734 28.0757 54.1828 27.4224C54.6923 26.7692 54.5757 25.8266 53.9224 25.3172L52.0776 27.6828ZM19.4825 14.3164C20.2972 14.4667 21.0795 13.9281 21.2298 13.1134C21.38 12.2987 20.8414 11.5165 20.0268 11.3662L19.4825 14.3164ZM26.5671 12.7451C25.7615 12.5517 24.9517 13.048 24.7583 13.8535C24.565 14.659 25.0612 15.4688 25.8667 15.6622L26.5671 12.7451ZM32.1296 17.3888C32.9197 17.6379 33.7621 17.1994 34.0113 16.4094C34.2605 15.6193 33.822 14.7768 33.0319 14.5277L32.1296 17.3888ZM39.3592 16.8259C38.5944 16.5076 37.7163 16.8695 37.398 17.6343C37.0796 18.3991 37.4416 19.2772 38.2064 19.5956L39.3592 16.8259ZM44.0189 22.3749C44.746 22.7719 45.6572 22.5043 46.0542 21.7772C46.4512 21.0501 46.1836 20.1388 45.4565 19.7418L44.0189 22.3749ZM51.2052 23.3082C50.5263 22.8334 49.5911 22.9988 49.1163 23.6777C48.6415 24.3566 48.807 25.2918 49.4858 25.7666L51.2052 23.3082ZM16.2596 13.7584C17.3616 13.9373 18.4354 14.1232 19.4825 14.3164L20.0268 11.3662C18.9575 11.1689 17.8625 10.9794 16.7404 10.7972L16.2596 13.7584ZM25.8667 15.6622C28.0802 16.1936 30.1615 16.7681 32.1296 17.3888L33.0319 14.5277C30.9916 13.8842 28.8426 13.2914 26.5671 12.7451L25.8667 15.6622ZM38.2064 19.5956C40.2602 20.4504 42.1875 21.375 44.0189 22.3749L45.4565 19.7418C43.5301 18.69 41.5075 17.7201 39.3592 16.8259L38.2064 19.5956ZM49.4858 25.7666C50.3651 26.3815 51.2275 27.0199 52.0776 27.6828L53.9224 25.3172C53.0329 24.6234 52.1286 23.954 51.2052 23.3082L49.4858 25.7666Z"
				fill="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<path
				d="M16.5 12.2778C33.431 15.0264 43.9504 19.4426 53 26.5"
				stroke="#E80024"
				stroke-width="3"
				stroke-linecap="round" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.CHEREPOVEC, '19.11')"
				(mouseleave)="mouseLeave()"
				cx="125"
				cy="129"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.UFA, '22.12')"
				(mouseleave)="mouseLeave()"
				cx="718"
				cy="94"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.TOLJATI, '21.12')"
				(mouseleave)="mouseLeave()"
				cx="652"
				cy="85"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.MOSCOW, '07.12')"
				(mouseleave)="mouseLeave()"
				cx="529"
				cy="139"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.MOSCOW, '23.11')"
				(mouseleave)="mouseLeave()"
				cx="254"
				cy="138"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.KAZAN, '16.11')"
				(mouseleave)="mouseLeave()"
				cx="59"
				cy="32"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				cx="10"
				cy="10"
				r="9"
				fill="#E80024"
				(mouseenter)="mouseEnter($event, CitiesNames.SOCHI, '13.11')"
				(mouseleave)="mouseLeave()" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.NIZHNEKAMSK, '17.11')"
				(mouseleave)="mouseLeave()"
				cx="89"
				cy="81"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.SPB, '08.12-19.12')"
				(mouseleave)="mouseLeave()"
				cx="586"
				cy="98"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.JAROSLAVL, '21.11')"
				(mouseleave)="mouseLeave()"
				cx="191"
				cy="157"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.MOSCOW, '27.11')"
				(mouseleave)="mouseLeave()"
				cx="387"
				cy="101"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.BALASHIHA, '25.11')"
				(mouseleave)="mouseLeave()"
				cx="315"
				cy="104"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
			<circle
				(mouseenter)="mouseEnter($event, CitiesNames.NIZHNIY, '29.11')"
				(mouseleave)="mouseLeave()"
				cx="454"
				cy="117"
				r="7.5"
				fill="#E80024"
				stroke="#E80024"
				stroke-width="3" />
		</svg>
	</div>
</div>
