import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent {
	@Input() form: FormGroup;
	@Output() formSubmit = new EventEmitter<void>();
	constructor(public modalRef: BsModalRef) {}
}
