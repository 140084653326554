import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SmartBroadcastContainerComponent } from '../smart/smart-broadcast-container/smart-broadcast-container.component';

@Component({
	selector: 'app-header',
	template: `
		<header class="header"
			><button (click)="showBroadcast()"
				>{{ title }}
				<span *ngIf="title === 'Собрано в пути:'">
					{{ count | transformNumber }} ₽</span
				></button
			></header
		>
	`,
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	@Input() title = 'Доброфура «Запад»';
	@Input() count;
	constructor(private modalService: BsModalService) {}

	showBroadcast() {
		const modalRef: BsModalRef = this.modalService.show(
			SmartBroadcastContainerComponent,
			{
				backdrop: true,
				animated: true,
				class: 'transparent modal-xl',
			}
		);
		modalRef.content.closeBtnName = 'Close';
	}
}
