<div class="moments">
	<div class="container">
		<div class="moments__slider-container">
			<button class="moments__slider-prev">
				<img src="../../../../../assets/images/arrow-red.svg" alt="" />
			</button>
			<button class="moments__slider-next">
				<img src="../../../../../assets/images/arrow-red.svg" alt="" />
			</button>
			<swiper-container
				class="moments__slider"
				init="false"
				appSwiperSlider
				[config]="config">
				<swiper-slide *ngFor="let foto of fotos">
					<div class="moments__image">
						<img [src]="foto" alt="foto" />
					</div>
				</swiper-slide>
			</swiper-container>
			<button class="moments__button">Добрые моменты</button>
		</div>
	</div>
</div>
