import { Pipe, PipeTransform } from '@angular/core';
import { ArticleData } from '../models/game.model';
import { Image } from '@ks89/angular-modal-gallery';

@Pipe({
	name: 'addGallery',
})
export class AddGalleryPipe implements PipeTransform {
	transform(data: ArticleData): ArticleData {
		const article = { ...data };
		if (!article?.slider) {
			return article;
		}
		const resultObj: ArticleData = { ...article };
		const sliderImages = this.getImagesAsArray(resultObj.slider);

		if (!sliderImages?.length) {
			return article;
		}

		const gallery: Image[] = sliderImages.map((img, i) => {
			return new Image(i, {
				img,
			});
		});

		return { ...article, gallery, sliderImages };
	}
	getImagesAsArray(slider: string): string[] {
		try {
			const jsonString = slider.replace(/^'|'$/g, '');
			const imageObject = JSON.parse(jsonString);
			return Object.values(imageObject);
		} catch (error) {
			return [];
		}
	}
}
