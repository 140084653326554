import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DonateFromSocket, GameDirection } from '../../models/game.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SmartBroadcastContainerComponent } from '../smart/smart-broadcast-container/smart-broadcast-container.component';

@Component({
	selector: 'app-truck-container',
	templateUrl: './truck-container.component.html',
	styleUrls: ['./truck-container.component.scss'],
})
export class TruckContainerComponent {
	@Input() direction: GameDirection = GameDirection.WEST;
	@Input() animateStatus = true;
	@Input() heartFactor = 1;
	@Input() donates: DonateFromSocket;
	@Output() fireClick = new EventEmitter<void>();
	protected readonly GameDirection = GameDirection;
	constructor(private modalService: BsModalService) {}

	showBroadcast() {
		const modalRef: BsModalRef = this.modalService.show(
			SmartBroadcastContainerComponent,
			{
				backdrop: true,
				animated: true,
				class: 'transparent modal-xl',
			}
		);
		modalRef.content.closeBtnName = 'Close';
	}
}
