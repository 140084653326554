import { Component, OnInit } from '@angular/core';
import {
	BehaviorSubject,
	fromEvent,
	map,
	Subscription,
	throttleTime,
} from 'rxjs';

@Component({
	selector: 'app-btn-up-page',
	template: `<button
		*ngIf="scrollTopReached$ | async"
		(click)="scrollToTop()"
		class="up-btn"
		><img src="assets/images/arrow.svg" alt="arrow"
	/></button>`,
	styleUrls: ['./btn-up-page.component.scss'],
})
export class BtnUpPageComponent implements OnInit {
	private scrollSubscription: Subscription;
	private offsetToTop = 400;
	scrollTopReached$ = new BehaviorSubject<boolean>(false);
	ngOnInit() {
		this.scrollSubscription = fromEvent(window, 'scroll')
			.pipe(
				throttleTime(100), // Ограничение частоты обработки событий
				map(() => window.scrollY || document.documentElement.scrollTop)
			)
			.subscribe(offset => {
				if (offset > this.offsetToTop) {
					this.scrollTopReached$.next(true);
				} else {
					this.scrollTopReached$.next(false);
				}
			});
	}

	scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}
}
