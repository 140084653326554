import {
	AuthState,
	reducer as AuthReducer,
} from '../modules/auth/state/auth.reducer';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
	GameState,
	reducer as GameReducer,
} from '../modules/shared/state/game.reducer';

export interface State {
	auth: AuthState;
	game: GameState;
}

export const reducers: ActionReducerMap<State, any> = {
	auth: AuthReducer,
	game: GameReducer,
};

export const metaReducers: Array<MetaReducer<State>> = [];
