import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'rayPower',
})
export class RayPowerPipe implements PipeTransform {
	transform(value: number): number {
		const result = Math.floor(value / 100);
		return result;
	}
}
