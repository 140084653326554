import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-banner',
	template: `
		<div class="banner">
			<div class="container">
				<h2 class="banner__text">
					Доброфуры уже <br />
					привезли добра на
				</h2>
				<p class="banner__sum"> {{ sum | transformNumber }}₽ </p>
			</div>
		</div>
	`,
	styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
	@Input() sum = 1000000;
}
