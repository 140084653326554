import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	@Input() form: FormGroup;
	@Input() isLogged: boolean;
	@Output() resetPassword = new EventEmitter<void>();
	@Output() submitForm = new EventEmitter<void>();
	@Output() swapToRegistration = new EventEmitter<void>();
	constructor(public bsModalRef: BsModalRef) {}
	handleReset() {
		this.resetPassword.emit();
		setTimeout(() => {
			this.bsModalRef.hide();
		}, 200);
	}
	handleToRegistration() {
		this.swapToRegistration.emit();
		setTimeout(() => {
			this.bsModalRef.hide();
		}, 200);
	}
}
