import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { UserModel } from '../../../../auth/models/user-model.model';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { DonateFromSocket, GameDirection } from '../../../models/game.model';
import { GameService } from '../../../service/game.service';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state';
import { selectIsEventActive } from '../../../state/game.reducer';

@Component({
	selector: 'app-smart-hero-container',
	template: `
		<app-hero-container
			[user]="user"
			[activeRoute]="activeChangeBtn$ | async"
			[userClicks]="userClicks$ | async"
			[donateSocket]="donates$ | async"
			[isEvent]="(isEvent$ | async) === false"
			(fireClick)="handleClickFire()"></app-hero-container>
	`,
	styleUrls: ['./smart-hero-container.component.scss'],
})
export class SmartHeroContainerComponent implements OnInit, OnDestroy {
	@Input() user: UserModel;
	donates$: Observable<DonateFromSocket>;
	isEvent$: Observable<boolean>;
	destroy$ = new Subject<void>();
	activeChangeBtn$ = new BehaviorSubject<GameDirection>(GameDirection.WEST);
	userClicks$ = new BehaviorSubject<number>(0);
	constructor(
		private router: Router,
		private service: GameService,
		private store: Store<State>
	) {}

	ngOnInit() {
		this.isEvent$ = this.store.select(selectIsEventActive);
		this.donates$ = this.service.globalDonateData$;
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
			if (
				event instanceof Scroll &&
				event.routerEvent instanceof NavigationEnd
			) {
				const url = event.routerEvent.url;
				if (url.includes('west')) {
					this.activeChangeBtn$.next(GameDirection.WEST);
				} else if (url.includes('east')) {
					this.activeChangeBtn$.next(GameDirection.EAST);
				}
			}
		});
	}

	ngOnDestroy() {
		this.userClicks$.next(0);
		this.destroy$.next();
		this.destroy$.complete();
		this.service.closeConnection();
	}

	handleClickFire() {
		if (!this?.user) {
			return;
		}
		this.service.userClicks$.next(
			this.service.userClicks$.value + this.user.ray
		);
		this.service.sendMessage(this.user.ray ?? 1);
	}
}
