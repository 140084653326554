import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
	BehaviorSubject,
	catchError,
	interval,
	map,
	Observable,
	of,
	takeWhile,
	tap,
} from 'rxjs';
import { UserModel } from '../../auth/models/user-model.model';
import {
	ArticleData,
	ArticleFromServer,
	CityEvent,
	DonateFromSocket,
	FundData,
	NewsImage,
	Raffle,
	RaffleWinner,
} from '../models/game.model';

@Injectable({
	providedIn: 'root',
})
export class GameService {
	private socket: WebSocket;
	public globalDonateData$ = new BehaviorSubject<DonateFromSocket>({
		allScore: 100,
		dayBank: 100,
		lastBank: 100,
		usersBank: 100,
		fullBank: 100,
	});
	public userClicks$ = new BehaviorSubject<number>(0);

	constructor(private httpClient: HttpClient) {}
	set onBoarding(token: string) {
		localStorage.setItem('dobrofura_onboarding', token);
	}

	// Геттер для получения токена из localStorage
	get onBoarding(): string {
		return localStorage.getItem('dobrofura_onboarding') || '';
	}
	//0 - west, 1 - east
	public getFunds(id: 1 | 0) {
		return this.httpClient
			.get<{ data: { data: FundData[] } }>(
				environment.apiEndpoint + `/news/fonds?id=${id}`
			)
			.pipe(
				map(response => response?.data),
				catchError(err => of(err))
			);
	}

	public getArticle(id: number): Observable<ArticleFromServer> {
		return this.httpClient
			.get<{ data: { news: ArticleData; newsImages: NewsImage[] } }>(
				environment.apiEndpoint + `/news/list?id=${id}`
			)
			.pipe(
				map(response => response?.data),
				catchError(err => of(err))
			);
	}

	public getRaffles(): Observable<Raffle[]> {
		return this.httpClient
			.get<{ data: Raffle[] }>(environment.apiEndpoint + `/news/raffles`)
			.pipe(
				map(response => response?.data),
				catchError(err => of(err))
			);
	}

	public getWinnersRaffles(id: number): Observable<RaffleWinner[]> {
		return this.httpClient
			.get<{ data: RaffleWinner[] }>(
				environment.apiEndpoint + `/news/raffle?id=${id}`
			)
			.pipe(
				map(response => response?.data),
				catchError(err => of(err))
			);
	}

	public conductRaffle(): Observable<ArticleData> {
		return this.httpClient
			.post<{ data: string }>(environment.apiEndpoint + `/game/raffle`, {})
			.pipe(
				map(response => response?.data),
				catchError(err => of(err))
			);
	}
	//0 - west, 1 - east
	public getEvents(tip: 1 | 0) {
		return this.httpClient
			.get<{ data: { data: CityEvent[] } }>(
				environment.apiEndpoint + `/news/events?tip=${tip}`
			)
			.pipe(
				map(response => response?.data),
				catchError(err => of(err))
			);
	}
	//0 - west, 1 - east, -1 - all
	public getFutureEvents(tip: 1 | 0 | -1) {
		return this.httpClient
			.get<{ data: { data: CityEvent[] } }>(
				environment.apiEndpoint + `/news/list-future?tip=${tip}`
			)
			.pipe(
				map(response => response?.data),
				catchError(err => of(err))
			);
	}
	//0 - west, 1 - east
	public getPastEvents(tip: 1 | 0) {
		return this.httpClient
			.get<{ data: { data: CityEvent[] } }>(
				environment.apiEndpoint + `/news/list-past?tip=${tip}`
			)
			.pipe(
				map(response => response?.data),
				catchError(err => of(err))
			);
	}

	public getAllBankScore() {
		return this.httpClient
			.get<{ data: { allBank: number } }>(
				environment.apiEndpoint + '/news/all-bank'
			)
			.pipe(
				tap(),
				map(response => response?.data.allBank),
				catchError(err => of(err))
			);
	}

	public openConnection(user?: UserModel): void {
		let url: string;
		if (user?.userID) {
			url = environment.wsEndpoint + `?user=${user.userID}`;
		} else {
			url = environment.wsNoAuthEndpoint;
		}
		if (this?.socket?.url === url) {
			return;
		}
		if (this?.socket) {
			this.closeConnection();
		}

		this.socket = new WebSocket(url);

		this.socket.onopen = event => {
			// console.log('Connection opened', event);
			// this.sendMessage('ping');
		};

		this.socket.onmessage = event => {
			const response = JSON.parse(event.data) as DonateFromSocket;
			this.globalDonateData$.next(response);
		};

		this.socket.onerror = event => {
			// console.error('WebSocket error', event);
		};

		this.socket.onclose = event => {
			// console.log('Connection closed', event);
		};
	}

	public sendMessage(ray: number | string): void {
		if (this.socket && this.socket.readyState === WebSocket.OPEN) {
			this.socket.send(`${ray}`);
		}
	}

	public closeConnection(): void {
		if (this?.socket) {
			this.socket.close();
			this.socket = null;
		}
	}
}
