import { animate, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { DonateFromSocket } from '../../models/game.model';

@Component({
	selector: 'app-beam-counter',
	templateUrl: './beam-counter.component.html',
	styleUrls: ['./beam-counter.component.scss'],
	animations: [
		trigger('nextRaffle', [transition('* => *', [animate('1s linear')])]),
	],
})
export class BeamCounterComponent {
	@Input() pastRaffle: number;
	@Input() nextRaffle: number;
	@Input() socket: DonateFromSocket;

	get width(): number {
		if (this.nextRaffle !== undefined && this.nextRaffle !== null) {
			const percentage =
				((this.pastRaffle - this.nextRaffle) / this.pastRaffle) * 100;
			return percentage > 100 ? 100 : percentage; // Убедитесь, что процент не превышает 100
		}

		return 0; // Если nextRaffle не определен, вернуть 0
	}
}
