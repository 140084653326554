import { Image } from '@ks89/angular-modal-gallery';

export enum GameDirection {
	WEST = 'west',
	EAST = 'east',
}

export interface Raffle {
	id: number;
}

export interface RaffleWinner {
	avatarID: number;
	awardID: number;
	nameAward: string;
	userID: number;
}

export interface DonateFromSocket {
	allScore: number;
	dayBank: number;
	lastBank: number;
	usersBank: number;
	fullBank: number;
}

export interface CityEvent {
	createdAt: string;
	expiredAt: string;
	id: number;
	pic: string;
	seconds_until_expiration: number;
	title: string;
}

export interface ArticleFromServer {
	news: ArticleData;
	newsImages: NewsImage[];
}

export interface ArticleData {
	bodyText: string;
	bodyText2: string;
	city: string;
	createdAt: string;
	description: string;
	expiredAt: string;
	h1: string;
	h2: string;
	keywords: string;
	newsID: number;
	pic: string;
	slider: string;
	subDescription: string;
	subTitle: string;
	tip: string;
	title: string;
	future: 1 | 0;
	gallery?: Image[];
	sliderImages?: string[];
}

export interface NewsImage {
	id: number;
	pic: string;
	newsID: number;
	createdAt: string;
}

export interface FundData {
	city: string;
	description: string;
	id: number;
	linkUrl: string;
	pic: string;
	supportFrom: string;
	tip: string;
	title: string;
}

export interface NewsData {
	bodyText: string;
	city: string;
	createdAt: string;
	description: string;
	expiredAt: string;
	h1: string;
	h2: string;
	keywords: string;
	newsID: number;
	pic: string;
	subDescription: string;
	subTitle: string;
	title: string;
}
