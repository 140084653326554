<div class="article__gallery container">
	<div class="article__gallery-top">Фотоотчет</div>
	<div class="article__gallery-inner">
		<img
			*ngFor="let image of imagesToShow; let i = index"
			[src]="image.pic"
			(click)="onShow(100, i)"
			class="article__gallery-image"
			alt="image" />
	</div>
	<button
		*ngIf="imagesToShow.length !== allImages.length"
		class="article__gallery-btn"
		(click)="moreImages()"
		>Показать больше</button
	>
</div>
