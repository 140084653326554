<!-- кабина меняется путем добавления модификаторов _east и _west -->
<div
	class="track _east"
	[ngClass]="{
		_west: direction === GameDirection.WEST,
		_east: direction === GameDirection.EAST
	}"
	appClickAnimation
	appPreventDoubleTapZoom
	[factor]="heartFactor"
	draggable="false"
	role="button">
	<ng-container [ngSwitch]="direction">
		<!-- блок с бегающим текстом. надо бы ему ту же самую анимацию задать вверх вниз. чтобы вместе с фурой двигался.   -->
		<div
			class="track__text"
			*ngIf="donates"
			[ngClass]="{ animate: animateStatus }">
			<p class="animate">
				<span
					>Везем&nbsp;добра&nbsp;на&nbsp;{{
						donates.fullBank | transformNumber
					}}&nbsp;₽
				</span>
				<span
					>Новое&nbsp;пожертвование:&nbsp;{{
						donates.lastBank | transformNumber
					}}&nbsp;₽
				</span>
				<span
					>Пожертвование&nbsp;дня:&nbsp;{{
						donates.dayBank | transformNumber
					}}&nbsp;₽
				</span>
			</p>
		</div>
		<img
			draggable="false"
			[ngClass]="{ animate: animateStatus }"
			*ngSwitchCase="'west'"
			loading="lazy"
			class="track__fura _west"
			ngSrc="truck-west.png"
			fill
			(click)="fireClick.emit()"
			alt="fura" />
		<img
			loading="lazy"
			draggable="false"
			[ngClass]="{ animate: animateStatus }"
			*ngSwitchCase="'east'"
			class="track__fura _east"
			ngSrc="truck-east.png"
			fill
			(click)="fireClick.emit()"
			alt="fura" />
	</ng-container>
	<img
		draggable="false"
		class="track__cabine"
		loading="lazy"
		[ngClass]="{ animate: animateStatus }"
		ngSrc="fura-cabine.png"
		width="153"
		height="189"
		alt="cabine"
		(click)="showBroadcast()" />
	<img
		draggable="false"
		class="track__wheel _wheel1"
		loading="lazy"
		[ngClass]="{ animate: animateStatus }"
		ngSrc="fura-wheel.png"
		width="62"
		height="62"
		alt="wheel" />
	<img
		draggable="false"
		class="track__wheel _wheel2"
		loading="lazy"
		[ngClass]="{ animate: animateStatus }"
		ngSrc="fura-wheel.png"
		width="62"
		height="62"
		alt="wheel" />
	<img
		draggable="false"
		class="track__wheel _wheel3"
		loading="lazy"
		[ngClass]="{ animate: animateStatus }"
		ngSrc="fura-wheel.png"
		width="62"
		height="62"
		alt="wheel" />
	<img
		draggable="false"
		class="track__wheel _wheel4"
		loading="lazy"
		[ngClass]="{ animate: animateStatus }"
		ngSrc="fura-wheel.png"
		width="62"
		height="62"
		alt="wheel" />
	<img
		draggable="false"
		class="track__wheel _wheel5"
		loading="lazy"
		[ngClass]="{ animate: animateStatus }"
		ngSrc="fura-wheel.png"
		width="62"
		height="62"
		alt="wheel" />
</div>
