<app-smart-hero-container [user]="user"></app-smart-hero-container>
<!--&lt;!&ndash; временно добавил сюда тег контейнера так как он должен оборачивать весь контент сайта, кроме экрана с фурой &ndash;&gt;-->
<div class="container">
	<div class="row-fund" *ngIf="user">
		<app-card-user-beam [user]="user"></app-card-user-beam>
		<app-smart-fund-support></app-smart-fund-support>
	</div>
	<br />
	<app-merchandise-slider-wrapper
		*ngIf="raffles?.length > 0"
		[raffleSlides]="raffles"
		[winSlides]="winners"></app-merchandise-slider-wrapper>
	<br />
	<app-fund-slider-container
		*ngIf="funds?.length > 0"
		[fundSlides]="funds"></app-fund-slider-container>
</div>
