import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { State } from '../../../../../state';
import { Store } from '@ngrx/store';
import { ResetPassword } from '../../../state/auth.actions';

@Component({
	selector: 'app-smart-password-reset',
	template: `
		<app-password-reset
			[form]="form"
			(formSubmit)="onFormSubmit()"></app-password-reset>
	`,
	styleUrls: ['./smart-password-reset.component.scss'],
})
export class SmartPasswordResetComponent implements OnInit {
	form: FormGroup;
	constructor(
		private fb: FormBuilder,
		private modalService: BsModalService,
		private store: Store<State>
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			email: ['', [Validators.email, Validators.required]],
		});
	}
	onFormSubmit() {
		if (!this?.form.valid) {
			return;
		}
		const { email } = this.form.value;
		this.store.dispatch(new ResetPassword(email));
		this.modalService.hide();
	}
}
