<div class="east-way" #parent>
	<div class="path-tooltip" #tooltip></div>

  <svg width="807" height="121" viewBox="0 0 807 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M112.979 11.0065C113.807 11.0184 114.488 10.3565 114.5 9.52814C114.512 8.6998 113.85 8.01868 113.021 8.00683L112.979 11.0065ZM17.3061 38.7648C32.936 28.8046 72.5416 10.4277 112.979 11.0065L113.021 8.00683C71.8001 7.41673 31.6182 26.0869 15.6939 36.2348L17.3061 38.7648Z" fill="#E80024"/>
    <path d="M219 37.2785C196.051 24.1086 174.108 15.319 129 10.6318" stroke="#E80024" stroke-width="3" stroke-linecap="round"/>
    <path d="M328.572 79.0002C290.987 75.6691 258.849 60.6729 232.5 45.2686" stroke="#E80024" stroke-width="3" stroke-linecap="round"/>
    <path d="M444.5 65.3262C417.543 71.6099 379.973 80.1045 343.5 79.7111" stroke="#E80024" stroke-width="3" stroke-linecap="round"/>
    <path d="M560.117 30.1479C512.502 31.993 502.751 50.6386 459.5 61.7816" stroke="#E80024" stroke-width="3" stroke-linecap="round"/>
    <path d="M687.5 58.4323C663.434 47.535 618.794 31.8335 575.5 30.1479" stroke="#E80024" stroke-width="3" stroke-linecap="round"/>
    <path d="M702.266 64.2102C701.553 63.7873 700.633 64.0219 700.21 64.7342C699.787 65.4465 700.022 66.3668 700.734 66.7898L702.266 64.2102ZM700.734 66.7898C704.061 68.7652 709.757 71.1044 716.766 73.8527C723.831 76.6229 732.325 79.843 741.413 83.6107C759.601 91.1503 779.989 100.811 795.568 113.175L797.432 110.825C781.511 98.1891 760.799 88.3997 742.562 80.8393C733.438 77.057 724.887 73.8146 717.862 71.0598C710.78 68.2831 705.339 66.0348 702.266 64.2102L700.734 66.7898Z" fill="#E80024"/>
    <circle cx="10" cy="39" r="9" fill="#E80024"/>
    <circle cx="121" cy="10" r="9" fill="#E80024"/>
    <circle cx="225" cy="42" r="9" fill="#E80024"/>
    <circle cx="452" cy="62" r="9" fill="#E80024"/>
    <circle cx="568" cy="30" r="9" fill="#E80024"/>
    <circle cx="694" cy="63" r="9" fill="#E80024"/>
    <circle cx="797" cy="111" r="9" fill="#E80024"/>
    <circle cx="336" cy="80" r="9" fill="#E80024"/>
  </svg>

</div>
