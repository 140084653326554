import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
	ActivatedRoute,
	Data,
	NavigationEnd,
	Router,
	RouterOutlet,
} from '@angular/router';
import { filter, map, Observable, Subject } from 'rxjs';
import { NavBtnData, RouteData } from './app-routing.module';
import { animate, style, transition, trigger } from '@angular/animations';
import { State } from './state';
import { Store } from '@ngrx/store';
import { GetUser } from './modules/auth/state/auth.actions';
import { AuthService } from './modules/auth/service/auth.service';
import { selectAllBank } from './modules/auth/state/auth.reducer';
import { GetEvents } from './modules/shared/state/game.actions';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		trigger('routeAnimations', [
			transition('* <=> *', [
				style({ opacity: 0 }),
				animate('300ms', style({ opacity: 1 })),
			]),
		]),
	],
})
export class AppComponent implements OnInit, OnDestroy {
	navBtnLeft$: Observable<NavBtnData>;
	navBtnRight$: Observable<NavBtnData>;
	title$: Observable<string>;
	allBank$: Observable<number>;
	showPortrait$: Observable<boolean>;
	orientation$ = new Subject();
	destroy$ = new Subject<void>();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store<State>,
		private authService: AuthService
	) {}

	ngOnInit() {
		this.store.dispatch(new GetUser());
		this.store.dispatch(new GetEvents());

		this.initRouterSubscriptions();

		this.allBank$ = this.store.select(selectAllBank);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	initRouterSubscriptions() {
		const routerEnd$ = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		);
		this.showPortrait$ = routerEnd$.pipe(
			map(
				() =>
					!(
						this.router.url.includes('future-events') ||
						this.router.url.includes('past-events')
					)
			)
		);
		const routerData$: Observable<RouteData | Data> = routerEnd$.pipe(
			map(() => this.activatedRoute.firstChild.snapshot?.data)
		);
		this.navBtnLeft$ = routerData$.pipe(map(data => data?.left));
		this.navBtnRight$ = routerData$.pipe(map(data => data?.right));
		this.title$ = routerData$.pipe(map(data => data?.title));
	}
}
