import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import { Mousewheel, Navigation } from 'swiper/modules';
import { AwardItem } from '../../models/profile.model';

@Component({
	selector: 'app-profile-awards',
	templateUrl: './profile-awards.component.html',
	styleUrls: [
		'./profile-awards.component.scss',
		'../profile-container/profile-container.component.scss',
	],
})
export class ProfileAwardsComponent {
	@Input() awardSlides: AwardItem[];
	@Output() showAddress = new EventEmitter<void>();

	public config: SwiperOptions = {
		modules: [Navigation, Mousewheel],
		autoHeight: true,
		slidesPerView: 1,
		slideActiveClass: '_active',
		centeredSlides: true,
		speed: 500,
		effect: 'cards',
		preventInteractionOnTransition: true,
		allowTouchMove: true,
		navigation: {
			enabled: true,
			nextEl: '#profile-awards-next',
			prevEl: '#profile-awards-prev',
			disabledClass: '_disabled',
		},
	};
}

export enum Awards {
	STICKERPACK = 'Стикерпак',
	SOCKS = 'Носки',
	MITTENS = 'Варежки',
	SCARF = 'Шарф',
	JUMPER = 'Свитер',
}
