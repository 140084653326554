import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserModel } from '../../../auth/models/user-model.model';

export enum ProfileClickType {
	LOGIN,
	REGISTRATION,
	PROFILE,
	CONDUCT_RAFFLE,
}

@Component({
	selector: 'app-profile-avatar',
	templateUrl: './profile-avatar.component.html',
	styleUrls: ['./profile-avatar.component.scss'],
})
export class ProfileAvatarComponent {
	@Input() user: UserModel;
	@Input() clicks = 0;
	@Input() isLogged: boolean;
	@Output() profileClick = new EventEmitter<ProfileClickType>();
	protected readonly ProfileClickType = ProfileClickType;
}
