<footer class="footer">
	<a class="footer__logo" href="#"
		><img src="../../../../../assets/images/footer-logo.svg" alt="logo"
	/></a>
	<a target="_blank" href="https://vk.com/dobrofon_info" class="footer__btn"
		>Задать вопрос</a
	>
	<ul class="footer__list">
		<li class="footer__list-item">
			<a
				target="_blank"
				class="footer__list-link"
				href="https://docs.google.com/document/d/1S1ZHF0Zsw9mj6GoJh_8jdgDSIUsdL1Z-JZJ2bjifwaA/edit?usp=sharing"
				>Соглашение</a
			>
		</li>
		<li class="footer__list-item">
			<a
				target="_blank"
				class="footer__list-link"
				href="https://docs.google.com/document/d/192N7YbK_5cRolKyFU0AdGi9JYny4u2JHXZPi9nglVmg/edit?usp=sharing"
				>Правовая информация</a
			>
		</li>
		<li class="footer__list-item">
			<a
				target="_blank"
				class="footer__list-link"
				href="https://docs.google.com/document/d/1qwUaN_rDFI7lReKMsbVu0Hl4MmHuePc8X8ZhDOu69ZM/edit?usp=sharing"
				>Условия акции</a
			>
		</li>
	</ul>
	<div class="footer__socials">
		<a
			target="_blank"
			class="footer__socials-item"
			href="https://vk.com/dobrofon_info">
			<svg
				width="49"
				height="48"
				viewBox="0 0 49 48"
				xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M43.0819 15.0625C43.3712 14.1812 43.0819 13.5406 41.8022 13.5406H37.5472C36.4739 13.5406 35.9782 14.1016 35.689 14.7016C35.689 14.7016 33.5001 19.8266 30.4435 23.1492C29.4522 24.1102 28.998 24.4305 28.461 24.4305C28.1717 24.4305 27.7996 24.1102 27.7996 23.2297V15.0211C27.7996 13.9805 27.4697 13.5 26.5605 13.5H19.8688C19.2074 13.5 18.7946 13.9805 18.7946 14.4609C18.7946 15.4617 20.323 15.7023 20.4879 18.5047V24.5938C20.4879 25.9148 20.2441 26.1562 19.703 26.1562C18.2576 26.1562 14.7468 20.9914 12.6408 15.1062C12.2313 13.9406 11.8153 13.5 10.7411 13.5H6.44544C5.20638 13.5 5 14.0602 5 14.6609C5 15.7422 6.44544 21.1867 11.7324 28.393C15.2432 33.3148 20.2409 35.9602 24.743 35.9602C27.4697 35.9602 27.7996 35.3594 27.7996 34.3586V30.6352C27.7996 29.4344 28.0474 29.2344 28.9152 29.2344C29.5343 29.2344 30.6498 29.5539 33.1694 31.9164C36.0603 34.7188 36.5559 36 38.1671 36H42.4214C43.6604 36 44.2389 35.3992 43.9082 34.2383C43.5369 33.0773 42.1321 31.3961 40.3145 29.3945C39.3233 28.2742 37.8364 27.0328 37.3822 26.432C36.7631 25.6313 36.928 25.3109 37.3822 24.5906C37.3408 24.5906 42.5457 17.4641 43.0819 15.0594" />
			</svg>
		</a>
		<a
			target="_blank"
			class="footer__socials-item"
			href="https://t.me/dobrofoninfo">
			<svg
				width="49"
				height="48"
				viewBox="0 0 49 48"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M35.855 36.0964L40.9703 12.1534C41.0217 11.9127 41.0062 11.663 40.9254 11.4302C40.8446 11.1975 40.7016 10.9903 40.5112 10.8303C40.3208 10.6703 40.0901 10.5634 39.8431 10.5208C39.5962 10.4782 39.3421 10.5013 39.1074 10.5879L8.68422 21.8245C8.48012 21.8999 8.3048 22.0354 8.18233 22.2123C8.05986 22.3892 7.99623 22.599 8.00017 22.8128C8.00412 23.0266 8.07544 23.234 8.20436 23.4065C8.33327 23.579 8.51347 23.7081 8.72022 23.7762L16.4959 26.3366L34.5503 15.133C34.6301 15.0834 34.726 15.0649 34.819 15.0812C34.9121 15.0975 34.9955 15.1474 35.0528 15.221C35.1102 15.2947 35.1373 15.3867 35.1289 15.479C35.1204 15.5712 35.077 15.6571 35.0072 15.7195L20.8898 28.366L20.1066 35.7977L20.2034 35.9021C20.2581 35.9609 20.3241 36.0085 20.3976 36.042C20.4712 36.0755 20.5508 36.0943 20.6319 36.0973C20.713 36.1004 20.7938 36.0875 20.8698 36.0596C20.9457 36.0316 21.0152 35.9891 21.0742 35.9345L25.582 31.765L33.0225 37.1546C33.2653 37.3304 33.5492 37.4435 33.8482 37.4837C34.1472 37.5238 34.4516 37.4896 34.7337 37.3843C35.0157 37.2789 35.2661 37.1058 35.4621 36.8808C35.658 36.6557 35.7931 36.386 35.855 36.0964Z" />
			</svg>
		</a>
	</div>
</footer>
