import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appPreventDoubleTapZoom]',
})
export class PreventDoubleTapZoomDirective {
	lastTouchEnd = 0;
	@HostListener('touchend', ['$event'])
	onTouchEnd(event: TouchEvent) {
		const currentTime = new Date().getTime();
		const tapLength = currentTime - this.lastTouchEnd;
		if (tapLength < 80 && tapLength > 0) {
			event.preventDefault();
		}
		this.lastTouchEnd = currentTime;
	}
}
