import { Action } from '@ngrx/store';
import {
	ArticleFromServer,
	CityEvent,
	FundData,
	NewsData,
	Raffle,
	RaffleWinner,
} from '../models/game.model';

export enum GameActionTypes {
	TOGGLE_ACTIVE_EVENT = '[Game] Toggle active event',
	GET_FUNDS = '[Game] Get supported funds',
	GET_FUNDS_SUCCESS = '[Game] Get supported funds success',
	GET_ALL_FUTURE_EVENTS = '[Game] Get all future events for user',
	GET_ALL_FUTURE_EVENTS_SUCCESS = '[Game] Get all future events for user success',
	GET_ARTICLE = '[Game] Get current article for user',
	GET_ARTICLE_SUCCESS = '[Game] Get current article for user success',
	GET_FUTURE_EVENTS = '[Game] Get future events for user',
	GET_FUTURE_EVENTS_SUCCESS = '[Game] Get future events for user success',
	GET_PAST_EVENTS = '[Game] Get all past events for user',
	GET_PAST_EVENTS_SUCCESS = '[Game] Get all pst events for user success',
	GET_EVENTS = '[Game] Get all events for user',
	GET_EVENTS_SUCCESS = '[Game] Get all events for user success',
	GET_ALL_BANK = '[Game] Get all donated money from users',
	GET_ALL_BANK_SUCCESS = '[Game] Get all donated money from users success',
	CONDUCT_RAFFLE = '[Game] Conduct raffle',
	NEXT_RAFFLE = '[Game] Set next raffle',
	GET_RAFFLES = '[Game] Get raffles',
	GET_RAFFLES_SUCCESS = '[Game] Get raffles success',
	GET_WINNERS_FOR_RAFFLE = '[Game] Get winners for raffle',
	GET_WINNERS_FOR_RAFFLE_SUCCESS = '[Game] Get winners for raffle success',
}

export class GetFunds implements Action {
	readonly type = GameActionTypes.GET_FUNDS;
	constructor(public payload: 1 | 0) {}
}

export class GetFundsSuccess implements Action {
	readonly type = GameActionTypes.GET_FUNDS_SUCCESS;
	constructor(public payload: FundData[]) {}
}
export class GetArticle implements Action {
	readonly type = GameActionTypes.GET_ARTICLE;
	constructor(public payload: number) {}
}

export class GetArticleSuccess implements Action {
	readonly type = GameActionTypes.GET_ARTICLE_SUCCESS;
	constructor(public payload: ArticleFromServer) {}
}

export class ToggleActiveEvent implements Action {
	readonly type = GameActionTypes.TOGGLE_ACTIVE_EVENT;
}

export class GetAllFutureEvents implements Action {
	readonly type = GameActionTypes.GET_ALL_FUTURE_EVENTS;
}

export class GetAllFutureEventsSuccess implements Action {
	readonly type = GameActionTypes.GET_ALL_FUTURE_EVENTS_SUCCESS;
	constructor(public payload: CityEvent[]) {}
}

export class GetPastEvents implements Action {
	readonly type = GameActionTypes.GET_PAST_EVENTS;
	constructor(public payload: 1 | 0) {}
}

export class GetPastEventsSuccess implements Action {
	readonly type = GameActionTypes.GET_PAST_EVENTS_SUCCESS;
	constructor(public payload: NewsData[]) {}
}

export class GetFutureEvents implements Action {
	readonly type = GameActionTypes.GET_FUTURE_EVENTS;
	constructor(public payload: 1 | 0 | -1) {}
}

export class GetFutureEventsSuccess implements Action {
	readonly type = GameActionTypes.GET_FUTURE_EVENTS_SUCCESS;
	constructor(public payload: NewsData[]) {}
}

export class GetEvents implements Action {
	readonly type = GameActionTypes.GET_EVENTS;
}

export class GetEventsSuccess implements Action {
	readonly type = GameActionTypes.GET_EVENTS_SUCCESS;
	constructor(
		public payload: {
			west: CityEvent[];
			east: CityEvent[];
		}
	) {}
}

export class GetAllBankScore implements Action {
	readonly type = GameActionTypes.GET_ALL_BANK;
}

export class GetAllBankScoreSuccess implements Action {
	readonly type = GameActionTypes.GET_ALL_BANK_SUCCESS;
	constructor(public payload: number) {}
}

export class ConductRaffle implements Action {
	readonly type = GameActionTypes.CONDUCT_RAFFLE;
}

export class GetRaffles implements Action {
	readonly type = GameActionTypes.GET_RAFFLES;
}

export class SetNextRaffle implements Action {
	readonly type = GameActionTypes.NEXT_RAFFLE;
	constructor(public payload: number) {}
}

export class GetRafflesSuccess implements Action {
	readonly type = GameActionTypes.GET_RAFFLES_SUCCESS;
	constructor(public payload: Raffle[]) {}
}
export class GetWinnersForRaffle implements Action {
	readonly type = GameActionTypes.GET_WINNERS_FOR_RAFFLE;
	constructor(public payload: number) {}
}

export class GetWinnersForRaffleSuccess implements Action {
	readonly type = GameActionTypes.GET_WINNERS_FOR_RAFFLE_SUCCESS;
	constructor(public payload: RaffleWinner[]) {}
}

export type GameActionUnion =
	| ConductRaffle
	| GetWinnersForRaffle
	| GetWinnersForRaffleSuccess
	| SetNextRaffle
	| ToggleActiveEvent
	| GetRaffles
	| GetRafflesSuccess
	| GetAllFutureEvents
	| GetAllFutureEventsSuccess
	| GetArticle
	| GetArticleSuccess
	| GetPastEvents
	| GetPastEventsSuccess
	| GetFutureEvents
	| GetFutureEventsSuccess
	| GetFunds
	| GetFundsSuccess
	| GetAllBankScore
	| GetAllBankScoreSuccess
	| GetEvents
	| GetEventsSuccess;
