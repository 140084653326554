import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewsData } from '../../models/game.model';

@Component({
	selector: 'app-card-report',
	template: `<div
		*ngIf="reportCard"
		class="card-report"
		[routerLink]="'/past-events-' + type + '/' + reportCard.newsID"
		role="button"
		(click)="cardClicked.emit(1)">
		<img class="card-report__image" [src]="reportCard.pic" alt="image" />
		<div class="card-report__inner">
			<div class="card-report__info">
				<div class="card-report__address">
					<div class="card-report__title">{{ reportCard.title }}</div>
					<div class="card-report__location">
						<img src="assets/images/location-black.svg" alt="location" />
						{{ reportCard.city }},
						{{ reportCard.expiredAt | date: 'd MMM, HH:mm' }}
					</div>
				</div>
			</div>
			<div class="card-report__text">{{ reportCard.bodyText }}</div>
			<div class="card-report__text">{{ reportCard.subDescription }}</div>
		</div>
	</div>`,
	styleUrls: ['./card-report.component.scss'],
})
export class CardReportComponent {
	@Input() reportCard: NewsData;
	@Input() type: 'east' | 'west';
	@Output() cardClicked = new EventEmitter<number>();
}

export interface ReportCardData {
	image: string;
	title: string;
	date: string;
	place: string;
	description: string;
}
