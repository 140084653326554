import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	Renderer2,
} from '@angular/core';
import {
	animate,
	AnimationBuilder,
	AnimationPlayer,
	keyframes,
	style,
} from '@angular/animations';

@Directive({
	selector: '[appClickAnimation]',
})
export class ClickAnimationDirective implements OnDestroy {
	private players: AnimationPlayer[] = [];
	private isAnimation = false;

	@Input() factor = 1; // Количество элементов для генерации

	constructor(
		private el: ElementRef,
		private builder: AnimationBuilder,
		private renderer: Renderer2
	) {}

	@HostListener('click', ['$event'])
	handleClick(event: MouseEvent) {
		if (!event.target['classList'].contains('track__fura')) {
			return;
		}

		const angleIncrement = 360 / this.actualFactor; // Угол между каждым сердечком
		for (let i = 0; i < this.actualFactor; i++) {
			this.createAndAnimateHeart(event, angleIncrement * i);
		}

		this.createAndAnimateCircle(event);
	}

	createAndAnimateCircle(event: MouseEvent) {
		const circle = this.renderer.createElement('div');
		this.renderer.addClass(circle, 'flash-circle');
		this.renderer.setStyle(circle, 'top', `${event.offsetY - 38}px`);
		this.renderer.setStyle(circle, 'left', `${event.offsetX - 38}px`);
		this.renderer.appendChild(this.el.nativeElement, circle);

		const metadata = animate(
			'600ms ease-out',
			keyframes([
				style({
					transform: 'scale(0.5)',
					opacity: 1,
					filter: 'blur(15px)',
					offset: 0,
				}),
				style({
					transform: 'scale(1)',
					opacity: 0.5,
					filter: 'blur(35px)',
					offset: 0.5,
				}),
				style({ opacity: 0, offset: 1 }),
			])
		);

		const player = this.builder.build(metadata).create(circle);
		player.onDone(() => {
			this.renderer.removeChild(this.el.nativeElement, circle);
		});
		player.play();
	}

	createAndAnimateHeart(event: MouseEvent, angle: number) {
		const heart = this.renderer.createElement('div');
		this.renderer.addClass(heart, 'flash-heart');
		this.renderer.setStyle(heart, 'top', `${event.offsetY - 12}px`);
		this.renderer.setStyle(heart, 'left', `${event.offsetX - 12}px`);
		this.renderer.appendChild(this.el.nativeElement, heart);

		const distance = this.actualFactor * 10; // Дистанция разлетания
		const radianAngle = (angle * Math.PI) / 180;
		const metadata = animate(
			'500ms ease-out',
			keyframes([
				style({
					transform: 'scale(0.5)',
					opacity: 0.3,
					filter: 'blur(3px)',
					offset: 0,
				}),
				style({
					transform: `scale(1) translate(${
						Math.cos(radianAngle) * distance
					}px, ${Math.sin(radianAngle) * distance}px)`,
					opacity: 0.95,
					offset: 1,
					filter: 'blur(1px)',
				}),
			])
		);

		const player = this.builder.build(metadata).create(heart);
		player.onDone(() => {
			this.renderer.removeChild(this.el.nativeElement, heart);
		});
		player.play();
		this.players.push(player);
	}

	ngOnDestroy() {
		this.players.forEach(player => player.destroy());
	}
	get actualFactor() {
		const ray = this.factor;
		if (ray === 1) {
			return 6;
		} else if (ray >= 2 && ray < 6) {
			return 9;
		} else if (ray >= 6 && ray < 11) {
			return 11;
		} else if (ray >= 11 && ray < 21) {
			return 13;
		} else if (ray >= 21 && ray < 51) {
			return 15;
		} else if (ray >= 51 && ray < 101) {
			return 17;
		} else if (ray >= 101) {
			return 19;
		} else {
			return 6;
		}
	}
}
