import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginModel } from '../../../models/login.model';
import { State } from '../../../../../state';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SmartRegistrationComponent } from '../smart-registration/smart-registration.component';
import { LogIn } from '../../../state/auth.actions';
import { SmartPasswordResetComponent } from '../smart-password-reset/smart-password-reset.component';

@Component({
	selector: 'app-smart-login',
	template: `
		<app-login
			[form]="form"
			(resetPassword)="resetPassword()"
			(submitForm)="performLogin()"
			(swapToRegistration)="swapToRegistration()"></app-login>
	`,
})
export class SmartLoginComponent implements OnInit {
	form: FormGroup;
	constructor(
		private fb: FormBuilder,
		private store: Store<State>,
		private modalService: BsModalService
	) {}

	ngOnInit() {
		this.initForm();
	}

	initForm() {
		this.form = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.minLength(8)]],
		});
	}

	resetPassword() {
		this.modalService.show(SmartPasswordResetComponent, {
			class: 'login-modal modal-xl',
		});
	}

	performLogin() {
		const data: LoginModel = this.form.value;
		this.store.dispatch(new LogIn(data));
	}

	swapToRegistration() {
		const modalRef: BsModalRef = this.modalService.show(
			SmartRegistrationComponent,
			{
				backdrop: true,
				class: 'login-modal modal-xl',
				animated: true,
			}
		);
		modalRef.content.closeBtnName = 'Close';
	}
}
