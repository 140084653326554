import { GameService } from '../service/game.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, map, mergeMap, switchMap } from 'rxjs';
import {
	ConductRaffle,
	GameActionTypes,
	GetAllBankScore,
	GetAllBankScoreSuccess,
	GetArticle,
	GetArticleSuccess,
	GetEvents,
	GetEventsSuccess,
	GetFunds,
	GetFundsSuccess,
	GetFutureEvents,
	GetFutureEventsSuccess,
	GetPastEvents,
	GetPastEventsSuccess,
	GetRaffles,
	GetRafflesSuccess,
	GetWinnersForRaffle,
	GetWinnersForRaffleSuccess,
} from './game.actions';
import { CityEvent } from '../models/game.model';
import { DateTime } from 'luxon';

@Injectable()
export class GameEffects {
	constructor(
		private actions$: Actions,
		private service: GameService
	) {}

	getAllBankScore$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetAllBankScore>(GameActionTypes.GET_ALL_BANK),
			mergeMap(() =>
				this.service.getAllBankScore().pipe(
					map(data => {
						return new GetAllBankScoreSuccess(data ?? 0);
					})
				)
			)
		)
	);

	getFunds$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetFunds>(GameActionTypes.GET_FUNDS),
			mergeMap(action =>
				this.service
					.getFunds(action.payload)
					.pipe(map(data => new GetFundsSuccess(data ?? [])))
			)
		)
	);

	getArticle$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetArticle>(GameActionTypes.GET_ARTICLE),
			mergeMap(action =>
				this.service
					.getArticle(action.payload)
					.pipe(map(data => new GetArticleSuccess(data)))
			)
		)
	);

	getRaffles$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetRaffles>(GameActionTypes.GET_RAFFLES),
			mergeMap(() =>
				this.service.getRaffles().pipe(map(data => new GetRafflesSuccess(data)))
			)
		)
	);

	getWinnersForRaffle$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetWinnersForRaffle>(GameActionTypes.GET_WINNERS_FOR_RAFFLE),
			mergeMap(action =>
				this.service
					.getWinnersRaffles(action.payload)
					.pipe(map(data => new GetWinnersForRaffleSuccess(data)))
			)
		)
	);

	conductRaffle$ = createEffect(() =>
		this.actions$.pipe(
			ofType<ConductRaffle>(GameActionTypes.CONDUCT_RAFFLE),
			switchMap(() =>
				this.service.conductRaffle().pipe(map(() => new GetRaffles()))
			)
		)
	);

	getEvents$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetEvents>(GameActionTypes.GET_EVENTS),
			mergeMap(() => {
				return forkJoin([
					this.service.getEvents(1),
					this.service.getEvents(0),
				]).pipe(
					map(([east, west]) => {
						return new GetEventsSuccess({
							east: east?.length > 0 ? east : [],
							west: west?.length > 0 ? west : [],
						});
					})
				);
			})
		)
	);

	getPastEvents$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetPastEvents>(GameActionTypes.GET_PAST_EVENTS),
			mergeMap(action => {
				return this.service
					.getPastEvents(action.payload)
					.pipe(
						map(data => new GetPastEventsSuccess(data?.length > 0 ? data : []))
					);
			})
		)
	);

	getFutureEvents$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetFutureEvents>(GameActionTypes.GET_FUTURE_EVENTS),
			mergeMap(action => {
				return this.service
					.getFutureEvents(action.payload)
					.pipe(
						map(
							data => new GetFutureEventsSuccess(data?.length > 0 ? data : [])
						)
					);
			})
		)
	);
}
