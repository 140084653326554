import { register } from 'swiper/element/bundle';
import { NgModule, isDevMode, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from './modules/shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { metaReducers, reducers } from './state';
import { environment } from '../environments/environment';
import { AuthEffects } from './modules/auth/state/auth.effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderInterceptor } from './modules/auth/interceptors/auth-token.interceptor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { GameEffects } from './modules/shared/state/game.effects';
import {
	NgOptimizedImage,
	provideImgixLoader,
	registerLocaleData,
} from '@angular/common';
import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeRu);

@NgModule({
	declarations: [AppComponent],
	imports: [
		NgOptimizedImage,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		StoreModule.forRoot(reducers, { metaReducers }),
		!environment.production
			? StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() })
			: [],
		EffectsModule.forRoot([AuthEffects, GameEffects]),
		SharedModule,
		HttpClientModule,
		ModalModule.forRoot(),
		ReactiveFormsModule,
		TooltipModule.forRoot(),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'ru' },
		provideImgixLoader('https://7d653b1d-b972-4c4b-b806-e6d9d3ad71dd.selstorage.ru/dobrofura/'),
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {
		register();
	}
}
