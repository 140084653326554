import { Component, Input } from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import { Mousewheel, Navigation } from 'swiper/modules';
import { RaffleWinner } from '../../models/game.model';

export interface MerchSlideData {
	name: string;
	id: string;
	image: string;
}
@Component({
	selector: 'app-merchandise-slider-container',
	templateUrl: './merchandise-slider-container.component.html',
	styleUrls: ['./merchandise-slider-container.component.scss'],
})
export class MerchandiseSliderContainerComponent {
	@Input() slides: RaffleWinner[] = [];
	public config: SwiperOptions = {
		modules: [Navigation, Mousewheel],
		autoHeight: true,
		slidesPerView: 2,
		speed: 500,
		mousewheel: true,
		spaceBetween: 15,
		preventInteractionOnTransition: true,
		allowTouchMove: true,
		breakpoints: {
			'1280': {
				slidesPerView: 'auto',
				spaceBetween: 10,
			},
		},
		navigation: {
			enabled: true,
			nextEl: '#merch-next',
			prevEl: '#merch-prev',
			disabledClass: '_disabled',
		},
	};
}
