import { Component } from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import { Navigation } from 'swiper/modules';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GameService } from '../../../service/game.service';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-smart-broadcast-container',
	templateUrl: './smart-broadcast-container.component.html',
	styleUrls: ['./smart-broadcast-container.component.scss'],
})
export class SmartBroadcastContainerComponent {
	title$ = new BehaviorSubject<'Запад' | 'Восток'>('Запад');
	public config: SwiperOptions = {
		modules: [Navigation],
		slidesPerView: 1,
		centeredSlides: true,
		speed: 500,
		allowTouchMove: true,
		navigation: {
			enabled: true,
			nextEl: '#broadcast-next',
			prevEl: '#broadcast-prev',
			disabledClass: '_disabled',
		},
	};

	constructor(
		public bsModalRef: BsModalRef,
		private gameService: GameService
	) {}

	sendRay() {
		this.gameService.userClicks$.next(this.gameService.userClicks$.value + 1);
		this.gameService.sendMessage(1);
	}
	changeTitle(index: number) {
		switch (index) {
			case 0:
				return this.title$.next('Запад');
			case 1:
				return this.title$.next('Восток');
		}
	}
}
