import { Component, Input } from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import { Mousewheel, Navigation } from 'swiper/modules';
import { FundData } from '../../models/game.model';

@Component({
	selector: 'app-fund-slider-container',
	templateUrl: './fund-slider-container.component.html',
	styleUrls: ['./fund-slider-container.component.scss'],
})
export class FundSliderContainerComponent {
	@Input() fundSlides: FundData[];
	public config: SwiperOptions = {
		modules: [Navigation],
		autoHeight: true,
		slidesPerView: 1,
		slideActiveClass: '_active',
		centeredSlides: true,
		speed: 500,
		spaceBetween: 20,
		preventInteractionOnTransition: true,
		allowTouchMove: true,
		breakpoints: {
			'1280': {
				slidesPerView: 3,
				centeredSlides: false,
			},
		},
		navigation: {
			enabled: true,
			prevEl: '#fund-cards-prev',
			nextEl: '#fund-cards-next',
			disabledClass: '_disabled',
		},
	};
}
