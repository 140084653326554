import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state';
import {
	selectCurrentArticle,
	selectCurrentArticleImages,
} from '../../../state/game.reducer';
import { ArticleData, NewsImage } from '../../../models/game.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-smart-article-report',
	template: `<app-article-report-container
		[article]="article$ | async | addGallery"
		[showArticleImages]="showArticleImages$ | async"
		[articleImages]="
			articleImages$ | async | convertToImage
		"></app-article-report-container>`,
})
export class SmartArticleReportComponent implements OnInit {
	article$: Observable<ArticleData>;
	articleImages$: Observable<NewsImage[]>;
	showArticleImages$ = new BehaviorSubject<boolean>(false);
	constructor(
		private store: Store<State>,
		private router: Router
	) {}

	ngOnInit() {
		this.article$ = this.store.select(selectCurrentArticle);
		this.articleImages$ = this.store.select(selectCurrentArticleImages);
		console.log(
			"this.router.url.includes('past-events')",
			this.router.url.includes('past-events')
		);
		this.showArticleImages$.next(this.router.url.includes('past-events'));
	}
}
