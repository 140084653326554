<div class="translation">
	<div class="translation__top">
		<div class="translation__nav">
			<button id="broadcast-prev" class="translation__nav-btn _prev">
				<svg
					width="10"
					height="12"
					viewBox="0 0 10 12"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M1.81164 0.233258C2.15228 -0.0777525 2.70456 -0.077753 3.0452 0.233258L8.74452 5.43686C9.08516 5.74788 9.08516 6.25212 8.74452 6.56314L3.0452 11.7667C2.70456 12.0778 2.15228 12.0778 1.81164 11.7667L1.25548 11.259C0.91484 10.9479 0.91484 10.4437 1.25548 10.1327L5.16507 6.56314C5.50571 6.25212 5.50571 5.74788 5.16507 5.43686L1.25548 1.86732C0.91484 1.55631 0.914841 1.05206 1.25548 0.741046L1.81164 0.233258Z" />
				</svg>
			</button>
			<span class="translation__nav-text"
				>Прямая трансляция из Доброфуры «{{ title$ | async }}»</span
			>
			<button id="broadcast-next" class="translation__nav-btn _next">
				<svg
					width="10"
					height="12"
					viewBox="0 0 10 12"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M1.81164 0.233258C2.15228 -0.0777525 2.70456 -0.077753 3.0452 0.233258L8.74452 5.43686C9.08516 5.74788 9.08516 6.25212 8.74452 6.56314L3.0452 11.7667C2.70456 12.0778 2.15228 12.0778 1.81164 11.7667L1.25548 11.259C0.91484 10.9479 0.91484 10.4437 1.25548 10.1327L5.16507 6.56314C5.50571 6.25212 5.50571 5.74788 5.16507 5.43686L1.25548 1.86732C0.91484 1.55631 0.914841 1.05206 1.25548 0.741046L1.81164 0.233258Z" />
				</svg>
			</button>
		</div>
		<button (click)="bsModalRef.hide()" class="translation__close">
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M2.45882 2.45882C3.07058 1.84706 4.06243 1.84706 4.67419 2.45882L8 5.78463L11.3258 2.45882C11.9376 1.84706 12.9294 1.84706 13.5412 2.45882C14.1529 3.07058 14.1529 4.06243 13.5412 4.67419L10.2154 8L13.5412 11.3258C14.1529 11.9376 14.1529 12.9294 13.5412 13.5412C12.9294 14.1529 11.9376 14.1529 11.3258 13.5412L8 10.2154L4.67419 13.5412C4.06243 14.1529 3.07058 14.1529 2.45882 13.5412C1.84706 12.9294 1.84706 11.9376 2.45882 11.3258L5.78463 8L2.45882 4.67419C1.84706 4.06243 1.84706 3.07058 2.45882 2.45882ZM3.5665 12.4335L3.6292 12.3708L3.5665 12.4335Z" />
			</svg>
		</button>
	</div>
	<swiper-container
		init="false"
		appSwiperSlider
		(activeSlide)="changeTitle($event)"
		[config]="config">
		<swiper-slide class="merchandise-slider__list-item">
			<div class="translation__body">
				<button (click)="bsModalRef.hide()" class="translation__close">
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M2.45882 2.45882C3.07058 1.84706 4.06243 1.84706 4.67419 2.45882L8 5.78463L11.3258 2.45882C11.9376 1.84706 12.9294 1.84706 13.5412 2.45882C14.1529 3.07058 14.1529 4.06243 13.5412 4.67419L10.2154 8L13.5412 11.3258C14.1529 11.9376 14.1529 12.9294 13.5412 13.5412C12.9294 14.1529 11.9376 14.1529 11.3258 13.5412L8 10.2154L4.67419 13.5412C4.06243 14.1529 3.07058 14.1529 2.45882 13.5412C1.84706 12.9294 1.84706 11.9376 2.45882 11.3258L5.78463 8L2.45882 4.67419C1.84706 4.06243 1.84706 3.07058 2.45882 2.45882ZM3.5665 12.4335L3.6292 12.3708L3.5665 12.4335Z" />
					</svg>
				</button>
				<iframe
					*ngIf="(title$ | async) === 'Запад'"
					src="https://vk.com/video_ext.php?oid=-207508001&id=456239106&hd=2&hash=018a3ac745ead224&autoplay=1"
					allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
					frameborder="0"
					allowfullscreen></iframe>
			</div>
		</swiper-slide>
		<swiper-slide class="merchandise-slider__list-item">
			<div class="translation__body">
				<button (click)="bsModalRef.hide()" class="translation__close">
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M2.45882 2.45882C3.07058 1.84706 4.06243 1.84706 4.67419 2.45882L8 5.78463L11.3258 2.45882C11.9376 1.84706 12.9294 1.84706 13.5412 2.45882C14.1529 3.07058 14.1529 4.06243 13.5412 4.67419L10.2154 8L13.5412 11.3258C14.1529 11.9376 14.1529 12.9294 13.5412 13.5412C12.9294 14.1529 11.9376 14.1529 11.3258 13.5412L8 10.2154L4.67419 13.5412C4.06243 14.1529 3.07058 14.1529 2.45882 13.5412C1.84706 12.9294 1.84706 11.9376 2.45882 11.3258L5.78463 8L2.45882 4.67419C1.84706 4.06243 1.84706 3.07058 2.45882 2.45882ZM3.5665 12.4335L3.6292 12.3708L3.5665 12.4335Z" />
					</svg>
				</button>
				<iframe
					*ngIf="(title$ | async) === 'Восток'"
					src="https://vk.com/video_ext.php?oid=-207508001&id=456239110&hd=2&hash=09611b5d17acc89f&autoplay=1"
					allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
					frameborder="0"
					allowfullscreen></iframe>
			</div>
		</swiper-slide>
	</swiper-container>
	<button class="translation__send" (click)="sendRay()"
		>Отправить луч добра <img src="assets/images/heart.svg" alt="heart"
	/></button>
</div>
