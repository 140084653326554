import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-registration',
	templateUrl: './registration.component.html',
	styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
	@Input() form: FormGroup;
	@Input() isLogged: boolean;
	@Output() submitForm = new EventEmitter<void>();
	constructor(public bsModalRef: BsModalRef) {}
}
