import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddressData, UserModel, VkAuthReq } from '../models/user-model.model';
import { environment } from '../../../../environments/environment';
import { catchError, map, Observable, of } from 'rxjs';
import { RegistrationModel } from '../models/registration.model';
import { AuthActionTypes } from '../state/auth.actions';
import { PaymentItem } from '../../profile/models/profile.model';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	#token = '';

	constructor(private http: HttpClient) {}

	// set token(value: string) {
	// 	this.#token = value;
	// }
	//
	// get token(): string {
	// 	// return this.#token;
	// }
	set token(token: string) {
		localStorage.setItem('dobro_token', token);
	}

	// Геттер для получения токена из localStorage
	get token(): string {
		return localStorage.getItem('dobro_token') || '';
	}

	// Метод для удаления токена из localStorage
	clearToken() {
		localStorage.removeItem('dobro_token');
	}

	changeAvatar(avatarID: number): Observable<string> {
		return this.http
			.put<{ data: string }>(environment.apiEndpoint + '/game/avatar', {
				avatarID,
			})
			.pipe(
				map(response => response.data),
				catchError(err => of(err))
			);
	}

	sendAddress(data: AddressData): Observable<string> {
		return this.http
			.post<{ data: string }>(environment.apiEndpoint + '/game/credits', {
				...data,
			})
			.pipe(
				map(response => response.data),
				catchError(err => of(err))
			);
	}

	getBankHistory(): Observable<PaymentItem[]> {
		return this.http
			.get<{ data: PaymentItem[] }>(environment.apiEndpoint + '/game/bank')
			.pipe(map(response => response.data));
	}

	getUser(): Observable<UserModel> {
		return this.http
			.get<{ data: UserModel }>(environment.apiEndpoint + '/game/user')
			.pipe(map(response => response.data));
	}

	resetPassword(email: string): Observable<string> {
		return this.http
			.post<{ data: string }>(
				environment.apiEndpoint + '/auth/update-password',
				{
					email,
				}
			)
			.pipe(
				map(response => response.data),
				catchError(e => of(e))
			);
	}

	donate(data: { score: number; invoiceID: string }): Observable<string> {
		return this.http
			.post<{ data: string }>(environment.apiEndpoint + '/game/bank', data)
			.pipe(
				map(response => response.data),
				catchError(e => of(e))
			);
	}

	loginUserOverVk(data: VkAuthReq): Observable<string> {
		return this.http
			.post<{ data: string }>(environment.apiEndpoint + '/auth/vk', {
				...data,
			})
			.pipe(
				map(response => response.data),
				catchError(e => of(e))
			);
	}

	authentication(
		user: RegistrationModel,
		type: AuthActionTypes
	): Observable<string> {
		switch (type) {
			default:
				return this.http
					.post<{ data: string }>(environment.apiEndpoint + '/auth/sign-in', {
						...user,
					})
					.pipe(
						map(response => response?.data),
						catchError(e => of(e))
					);
			case AuthActionTypes.REGISTRATION:
				return this.http
					.post<{ data: string }>(environment.apiEndpoint + '/auth/sign-up', {
						...user,
					})
					.pipe(
						map(response => response?.data),
						catchError(e => of(e))
					);
		}
	}
}
