<div class="profile-avatar">
	<!--  блок с кнопками когда не залогинен, пока скрыл.  -->
	<!--  блок когда залогинен  -->
	<div *ngIf="isLogged && user; else authButtons" class="profile-avatar__info">
		<button
			class="profile-avatar__info-btn"
			(click)="profileClick.emit(ProfileClickType.PROFILE)"
			>Профиль</button
		>
		<div class="profile-avatar__info-ava"
			><img [src]="'assets/images/avatars/' + user.avatarID + '.png'" alt="ava"
		/></div>
		<div class="profile-avatar__info-state profile-avatar__info-rang">
			<div class="profile-avatar__info-state-item _red">Ранг</div>
			<div class="profile-avatar__info-state-item _white">{{
				user.rank.nameRank
			}}</div>
		</div>
		<div class="profile-avatar__info-state profile-avatar__info-beams">
			<div class="profile-avatar__info-state-item _red">Отправлено лучей</div>
			<div class="profile-avatar__info-state-item _white">
				<ng-container *ngIf="user.score + clicks >= 0; else noScore">
					{{ user.score + clicks | transformNumber }}</ng-container
				>
				<ng-template #noScore>0</ng-template>
			</div>
		</div>
		<div class="profile-avatar__info-state profile-avatar__info-id">
			<div class="profile-avatar__info-state-item _red">Мой ID</div>
			<div class="profile-avatar__info-state-item _white">{{
				user.userID
			}}</div>
		</div>
		<button
			*ngIf="user?.isAdmin"
			class="profile-avatar__btns-btn"
			(click)="profileClick.emit(ProfileClickType.CONDUCT_RAFFLE)"
			>Провести розыгрыш</button
		>
	</div>

	<ng-template #authButtons>
		<div class="profile-avatar__btns">
			<button
				class="profile-avatar__btns-btn"
				(click)="profileClick.emit(ProfileClickType.REGISTRATION)"
				>Регистрация</button
			>
			<button
				class="profile-avatar__btns-btn"
				(click)="profileClick.emit(ProfileClickType.LOGIN)"
				>Вход</button
			>
		</div>
	</ng-template>
</div>
