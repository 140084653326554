import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RangPopupComponent } from '../../../profile/components/rang-popup/rang-popup.component';
import { RatingPopupComponent } from '../../../profile/components/rating-popup/rating-popup.component';
import { DescriptionPopupComponent } from '../../../profile/components/description-popup/description-popup.component';

@Component({
	selector: 'app-btn-question',
	template: ` <ng-template #tooltip
			><div [innerHtml]="content"></div
		></ng-template>
		<button
			class="profile__question-btn"
			[containerClass]="containerClass"
			[outsideClick]="true"
			[popover]="tooltip">
			<svg
				width="11"
				height="16"
				viewBox="0 0 11 16"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M3.22 8.18286C3.22 7.42857 3.81429 6.62857 5.00286 5.76C6.28286 4.8 6.48857 3.61143 5.14 3.61143C4.52286 3.61143 4.15714 4.04571 3.7 5.05143L0.5 3.63429C1.14 1.23429 2.87714 0 5.27714 0C8.02 0 10.0771 1.66857 10.0771 4.11429C10.0771 5.32572 9.62 6.12572 8.36286 7.2L7.92857 7.56572C7.17429 8.18286 6.78572 8.66286 6.78572 9.02857V9.32572H3.49429C3.31143 8.89143 3.22 8.52571 3.22 8.18286ZM5.23143 15.6343C1.78 15.6343 1.75714 10.5143 5.23143 10.5143C8.61429 10.5143 8.59143 15.6343 5.23143 15.6343Z" />
			</svg>
		</button>`,
	styleUrls: ['./btn-question.component.scss'],
})
export class BtnQuestionComponent {
	@Input() type: 'rang' | 'rating';
	@Input() content: string;
	@Input() containerClass = 'tooltip-custom';

	constructor(private bs: BsModalService) {}
	options = {
		backdrop: true,
		animated: true,
		class: 'transparent',
	};

	openModal(type?: string) {
		switch (type) {
			case 'rang':
				return this.bs.show(RangPopupComponent, this.options);
			case 'rating':
				return this.bs.show(RatingPopupComponent, this.options);
			default:
				return this.bs.show(DescriptionPopupComponent, this.options);
		}
	}
}
