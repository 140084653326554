import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserModel } from '../../../auth/models/user-model.model';
import { DonateFromSocket, GameDirection } from '../../models/game.model';

@Component({
	selector: 'app-hero-container',
	templateUrl: './hero-container.component.html',
	styleUrls: ['./hero-container.component.scss'],
})
export class HeroContainerComponent {
	@Input() activeRoute: GameDirection;
	@Input() user: UserModel;
	@Input() donateSocket: DonateFromSocket;
	@Input() isEvent = false;
	@Input() userClicks: number;
	@Output() fireClick = new EventEmitter<number>();
	protected readonly GameDirection = GameDirection;
}
