import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-wagon-change-button',
	templateUrl: './wagon-change-button.component.html',
	styleUrls: ['./wagon-change-button.component.scss'],
})
export class WagonChangeButtonComponent {
	@Input() type: 'east' | 'west';
	@Input() active: 'east' | 'west';

	get link() {
		if (this.type === 'east') {
			return '/east-wagon';
		} else {
			return '/west-wagon';
		}
	}
}
