import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { historyItems } from '../../../../../assets/mock-data';
import { PaymentItem } from '../../models/profile.model';

export interface OperationItem {
	date: Date | string;
	points: number;
	value: number;
}

@Component({
	selector: 'app-operation-history',
	templateUrl: './operation-history.component.html',
	styleUrls: ['./operation-history.component.scss'],
})
export class OperationHistoryComponent {
	@Input() bankHistory: PaymentItem[];
	constructor(protected bsModal: BsModalRef) {}
}
