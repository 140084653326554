import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		if (req.url.includes('/api/v1/') && !req.url.includes('auth')) {
			const headers = new HttpHeaders().set(
				'X-TOKEN',
				`${this.authService.token}`
			);
			const clonedRequest = req.clone({ headers });
			return next.handle(clonedRequest);
		}

		return next.handle(req);
	}
}
