import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ProfileClickType } from '../../profile-avatar/profile-avatar.component';
import { Router } from '@angular/router';
import { SmartLoginComponent } from '../../../../auth/components/smart/smart-login/smart-login.component';
import { SmartRegistrationComponent } from '../../../../auth/components/smart/smart-registration/smart-registration.component';
import { ModalOptions } from 'ngx-bootstrap/modal/modal-options.class';
import { State } from '../../../../../state';
import { Store } from '@ngrx/store';
import {
	selectIsLogged,
	selectUser,
} from '../../../../auth/state/auth.reducer';
import { UserModel } from '../../../../auth/models/user-model.model';
import { GameService } from '../../../service/game.service';
import { UpdateUser } from '../../../../auth/state/auth.actions';
import { ConductRaffle } from '../../../state/game.actions';

@Component({
	selector: 'app-smart-profile-avatar',
	template: `
		<app-profile-avatar
			[user]="user$ | async"
			[isLogged]="isLogged$ | async"
			[clicks]="clicks$ | async"
			(profileClick)="handleProfileClick($event)">
		</app-profile-avatar>
	`,
	styleUrls: ['./smart-profile-avatar.component.scss'],
})
export class SmartProfileAvatarComponent implements OnInit {
	isLogged$: Observable<boolean>;
	clicks$: Observable<number>;
	user$: Observable<UserModel>;
	modalWindowRef?: BsModalRef;
	modalOptions: ModalOptions = {
		backdrop: true,
		animated: true,
		class: 'login-modal modal-xl',
	};

	constructor(
		private modalService: BsModalService,
		private router: Router,
		private store: Store<State>,
		private service: GameService
	) {}

	ngOnInit() {
		this.isLogged$ = this.store.select(selectIsLogged);
		this.user$ = this.store.select(selectUser);
		this.clicks$ = this.service.userClicks$;
	}

	handleProfileClick(type: ProfileClickType) {
		switch (type) {
			case ProfileClickType.REGISTRATION:
				this.modalWindowRef = this.modalService.show(
					SmartRegistrationComponent,
					{
						...this.modalOptions,
					}
				);
				this.modalWindowRef.content.closeBtnName = 'Close';
				break;
			case ProfileClickType.LOGIN:
				this.modalWindowRef = this.modalService.show(SmartLoginComponent, {
					...this.modalOptions,
				});
				this.modalWindowRef.content.closeBtnName = 'Close';
				break;
			case ProfileClickType.PROFILE:
				this.store.dispatch(new UpdateUser());
				this.router.navigate(['profile']);
				break;
			case ProfileClickType.CONDUCT_RAFFLE:
				this.store.dispatch(new ConductRaffle());
				break;
			default:
				break;
		}
	}
}
