import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './components/registration/registration.component';
import { LoginComponent } from './components/login/login.component';
import { SmartLoginComponent } from './components/smart/smart-login/smart-login.component';
import { SmartRegistrationComponent } from './components/smart/smart-registration/smart-registration.component';
import { VkOneTapDirective } from './directives/vk-one-tap.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { SmartPasswordResetComponent } from './components/smart/smart-password-reset/smart-password-reset.component';
import { ShowErrorsDirective } from './directives/show-errors.directive';

@NgModule({
	declarations: [
		RegistrationComponent,
		LoginComponent,
		SmartLoginComponent,
		SmartRegistrationComponent,
		VkOneTapDirective,
		PasswordResetComponent,
		SmartPasswordResetComponent,
		ShowErrorsDirective,
	],
	imports: [CommonModule, ReactiveFormsModule],
	exports: [LoginComponent, PasswordResetComponent, ShowErrorsDirective],
})
export class AuthModule {}
