import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	selectIsLogged,
	selectUser,
} from '../../../../auth/state/auth.reducer';
import { combineLatestWith, Observable, Subject, takeUntil } from 'rxjs';
import { UserModel, VkAuthReq } from '../../../../auth/models/user-model.model';
import { State } from '../../../../../state';
import { Store } from '@ngrx/store';
import { GameService } from '../../../service/game.service';
import { GetAllBankScore, GetRaffles } from '../../../state/game.actions';
import { FundData, Raffle, RaffleWinner } from '../../../models/game.model';
import {
	selectFunds,
	selectRaffles,
	selectWinners,
} from '../../../state/game.reducer';
import { Router } from '@angular/router';
import { VkAuth } from '../../../../auth/state/auth.actions';

@Component({
	selector: 'app-smart-wagon-screen',
	template: `<app-wagon-screen
		[user]="user$ | async"
		[raffles]="raffles$ | async"
		[winners]="winners$ | async"
		[funds]="funds$ | async"></app-wagon-screen>`,
	styleUrls: ['./smart-wagon-screen.component.scss'],
})
export class SmartWagonScreenComponent implements OnInit, OnDestroy {
	raffles$: Observable<Raffle[]>;
	winners$: Observable<RaffleWinner[]>;
	user$: Observable<UserModel>;
	funds$: Observable<FundData[]>;
	destroy$ = new Subject<void>();

	constructor(
		private store: Store<State>,
		private router: Router,
		private gameService: GameService
	) {}

	ngOnInit() {
		this.checkVkRedirect();
		this.store.dispatch(new GetRaffles());
		this.store.dispatch(new GetAllBankScore());
		this.raffles$ = this.store.select(selectRaffles);
		this.winners$ = this.store.select(selectWinners);
		this.user$ = this.store.select(selectUser);
		this.funds$ = this.store.select(selectFunds);

		this.store
			.select(selectIsLogged)
			.pipe(combineLatestWith(this.user$), takeUntil(this.destroy$))
			.subscribe(([isLogged, user]) => {
				if (isLogged) {
					this.gameService.openConnection(user);
				} else {
					this.gameService.openConnection();
				}
			});
	}

	ngOnDestroy() {
		this.gameService.closeConnection();
		this.destroy$.next();
		this.destroy$.complete();
	}

	checkVkRedirect() {
		const url = this.router.url;
		const urlParams = new URLSearchParams(url.split('?')[1]);
		if (urlParams.has('payload')) {
			if (JSON.parse(urlParams.get('payload'))?.token) {
				const { token, uuid } = JSON.parse(
					urlParams.get('payload')
				) as VkAuthReq;
				this.store.dispatch(new VkAuth({ token, uuid }));
			}
		}
	}
}
