import { Component, OnDestroy, OnInit } from '@angular/core';
import { GameService } from '../../../service/game.service';
import { interval, map, Observable, Subject, takeWhile } from 'rxjs';
import { DonateFromSocket } from '../../../models/game.model';
import {
	mockRafflesData,
	RaffleData,
} from '../../../../../../assets/mock-data';

@Component({
	selector: 'app-smart-beam-counter',
	template: `<app-beam-counter
		[pastRaffle]="diffToPastRaffle"
		[nextRaffle]="counterToNextRaffle$ | async"
		[socket]="allScore$ | async"></app-beam-counter>`,
	styleUrls: ['./smart-beam-counter.component.scss'],
})
export class SmartBeamCounterComponent implements OnInit, OnDestroy {
	allScore$: Observable<DonateFromSocket>;
	destroy$ = new Subject<void>();
	counterToNextRaffle$: Observable<number>;
	diffToPastRaffle: number;
	constructor(private service: GameService) {}

	ngOnInit(): void {
		const { past, next } = this.calculateDateDifference();
		this.counterToNextRaffle$ = this.createTimer$(next);
		this.diffToPastRaffle = past;
		this.allScore$ = this.service.globalDonateData$;
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	createTimer$(initialSeconds: number): Observable<number> {
		return interval(1000).pipe(
			map(elapsedSeconds => initialSeconds - elapsedSeconds),
			takeWhile(remainingSeconds => remainingSeconds >= 0)
		);
	}

	calculateDateDifference(): { past: number; next: number } {
		const raffles: RaffleData[] = [...mockRafflesData];
		const pastRaffleItem = [...raffles]
			.reverse()
			.find(item => item.expiredAt.diffNow().milliseconds < 0);
		const nextRaffleItem = raffles.find(
			item => item.expiredAt.diffNow().milliseconds >= 0
		);
		const next = nextRaffleItem.expiredAt.diffNow().milliseconds / 1000;
		const past = pastRaffleItem.expiredAt.diffNow().milliseconds / 1000 - next;
		return {
			past: Math.abs(past),
			next: Math.floor(next),
		};
	}
}
