import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { State } from '../../../../../state';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { selectUser } from '../../../../auth/state/auth.reducer';
import { v4 as uuidv4 } from 'uuid';
import { Donate, GetBankHistory } from '../../../../auth/state/auth.actions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SmartOperationHistoryComponent } from '../../../../profile/components/smart/smart-operation-history/smart-operation-history.component';
import { environment } from '../../../../../../environments/environment';

declare let cp: any;
export interface PaymentSuccess {
	accountId: string;
	amount: number;
	autoClose: number;
	currency: string;
	data: { myProp: string };
	description: string;
	email: string;
	invoiceId: string;
	publicId: string;
	skin: string;
}

@Component({
	selector: 'app-smart-fund-support',
	template: `
		<app-fund-support
			[form]="form"
			(submitForm)="supportFund()"
			(showHistory)="showHistory()"></app-fund-support>
	`,
	styleUrls: ['./smart-fund-support.component.scss'],
})
export class SmartFundSupportComponent implements OnInit {
	form: FormGroup;
	constructor(
		private fb: FormBuilder,
		private store: Store<State>,
		private modalService: BsModalService
	) {}

	ngOnInit() {
		this.store.dispatch(new GetBankHistory());
		this.initForm();
	}

	initForm() {
		this.form = this.fb.group({
			sum: [
				100,
				[Validators.required, Validators.min(1), Validators.max(1000000000)],
			],
		});
	}

	async supportFund() {
		const { sum } = this.form.value;
		const user = await firstValueFrom(this.store.select(selectUser));
		const invoiceId = uuidv4();
		const widget = new cp.CloudPayments({
			language: 'ru-RU',
			email: '',
			applePaySupport: false,
			googlePaySupport: false,
			yandexPaySupport: false,
			tinkoffInstallmentSupport: false,
		});
		const actionCall = (data: PaymentSuccess) =>
			this.store.dispatch(
				new Donate({ score: data.amount, invoiceID: data.invoiceId })
			);
		widget.pay(
			'charge',
			{
				//options
				publicId: environment.publicIdForWidget, //id из личного кабинета
				description: `Благотворительное пожертвование в пользу БФ 'Дорога жизни'`, //назначение
				amount: Math.ceil(sum), //сумма
				currency: 'RUB', //валюта
				accountId: user.email, //идентификатор плательщика (необязательно)
				invoiceId, //номер заказа  (необязательно)
				email: user.email, //email плательщика (необязательно)
				skin: 'mini', //дизайн виджета (необязательно)
				autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
				data: {
					myProp: 'payment from dobrofura',
				},
			},
			{
				onSuccess: function (options: PaymentSuccess) {
					// console.log('onSuccess', options);
					setTimeout(() => actionCall(options), 2000);
					// success
					//действие при успешной оплате
				},
				onFail: function (reason: any, options: any) {
					console.log('onFail', reason, options);
					// fail
					//действие при неуспешной оплате
				},
				onComplete: function (paymentResult: any, options: any) {
					// console.log('onComplete', paymentResult, options);
					//Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
					//например вызов вашей аналитики
				},
			}
		);
	}

	showHistory() {
		this.store.dispatch(new GetBankHistory());
		this.modalService.show(SmartOperationHistoryComponent, {
			animated: true,
			class: 'transparent',
		});
	}
}
