<div class="recover">
	<div class="recover__inner">
		<button class="recover__close" type="button" (click)="modalRef.hide()">
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M2.45882 2.45882C3.07058 1.84706 4.06243 1.84706 4.67419 2.45882L8 5.78463L11.3258 2.45882C11.9376 1.84706 12.9294 1.84706 13.5412 2.45882C14.1529 3.07058 14.1529 4.06243 13.5412 4.67419L10.2154 8L13.5412 11.3258C14.1529 11.9376 14.1529 12.9294 13.5412 13.5412C12.9294 14.1529 11.9376 14.1529 11.3258 13.5412L8 10.2154L4.67419 13.5412C4.06243 14.1529 3.07058 14.1529 2.45882 13.5412C1.84706 12.9294 1.84706 11.9376 2.45882 11.3258L5.78463 8L2.45882 4.67419C1.84706 4.06243 1.84706 3.07058 2.45882 2.45882ZM3.5665 12.4335L3.6292 12.3708L3.5665 12.4335Z" />
			</svg>
		</button>
		<div class="recover__left">
			<div class="recover__title">Восстановление пароля</div>
			<form
				class="recover__form"
				[formGroup]="form"
				(ngSubmit)="formSubmit.emit()"
				(keydown.enter)="$event.preventDefault()"
				(keydown.shift.enter)="$event.preventDefault()">
				<div class="recover__label">Введите адрес почты</div>
				<div class="recover__input form-group form-field">
					<input
						formControlName="email"
						appShowErrors
						type="text"
						placeholder="пример@почта.ru" />
				</div>
				<div class="recover__label _last"
					>Новый пароль от вашего аккаунта будет отправлен на указанную
					почту</div
				>
				<div class="recover__btns">
					<button type="submit" class="recover__btns-log"
						>Восстановить пароль</button
					>
				</div>
			</form>
		</div>
		<div class="recover__right">
			<img
				class="recover__image"
				src="assets/images/password-reset-bg.png"
				alt="fura" />
		</div>
	</div>
</div>
