import {
	AfterViewInit,
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import Swiper from 'swiper';

@Directive({
	selector: '[appSwiperSlider]',
})
export class SwiperSliderDirective implements AfterViewInit, OnDestroy {
	@Input() slideTo: number;
	@Input() set setActiveSlide(slide: number) {
		if (typeof slide === 'number' && this.swiper) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.el.nativeElement.swiper.slideTo(slide);
		}
	}
	@Input() config?: SwiperOptions;
	@Output() activeSlide = new EventEmitter<number>();

	swiper: Swiper;
	constructor(private el: ElementRef<HTMLElement>) {}

	ngAfterViewInit() {
		Object.assign(this.el.nativeElement, this.config);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.el.nativeElement.initialize();
		this.swiper = this.el.nativeElement['swiper'];
		// this.swiper.initialize();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.el.nativeElement.swiper.slideTo(this.slideTo);
		this.el.nativeElement.addEventListener(
			'swiperslidechange',
			this.slideChangeHandler.bind(this)
		);
		console.log('this.swiper', this.el.nativeElement['swiper']);
	}

	ngOnDestroy() {
		this.el.nativeElement.removeEventListener(
			'swiperslidechange',
			this.slideChangeHandler.bind(this)
		);
	}

	private slideChangeHandler(event: Event) {
		this.activeSlide.emit(event['detail'][0].activeIndex);
	}
}
