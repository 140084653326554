import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, take, tap } from 'rxjs';
import { inject } from '@angular/core';
import { State } from '../../../state';
import { selectIsLogged } from '../state/auth.reducer';

const isAuthenticated = ():
	| boolean
	| UrlTree
	| Observable<boolean | UrlTree>
	| Promise<boolean | UrlTree> => {
	const store = inject(Store<State>);
	const router = inject(Router);
	return store.select(selectIsLogged).pipe(
		take(1),
		tap((isAuthenticated: boolean) => {
			if (!isAuthenticated) {
				router.navigate(['/']);
			}
		})
	);
};

export const canActivate: CanActivateFn = isAuthenticated;
