import { Component, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SwiperOptions } from 'swiper/types';
import { Pagination } from 'swiper/modules';
import { BehaviorSubject } from 'rxjs';
import { GameService } from '../../service/game.service';

@Component({
	selector: 'app-onboarding',
	templateUrl: './onboarding.component.html',
	styleUrls: ['./onboarding.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class OnboardingComponent {
	btnName$ = new BehaviorSubject<string>('Рад познакомиться!');
	activeSlide = 0;
	constructor(
		public bsModelRef: BsModalRef,
		private gameService: GameService
	) {}
	public config: SwiperOptions = {
		autoHeight: true,
		slidesPerView: 1,
		speed: 500,
		spaceBetween: 30,
		lazyPreloadPrevNext: 1,
		modules: [Pagination],
		pagination: {
			enabled: true,
			clickable: true,
			el: '.onboarding__pagination',
			modifierClass: 'onboarding-',
			bulletClass: 'onboarding-pagination-bullet',
			bulletActiveClass: 'onboarding-pagination-bullet-active',
		},
	};

	slides: { title: string; description: string; image: string }[] = [
		{
			title: 'Знакомься, Доброфура!',
			description: `Она большая, красная, уютная и о-о-очень добрая.
А самое классное — что она не одна. <strong class="onboarding__text--red">Их целых две!</strong>
</br>
</br>
Им обеим предстоит <strong class="onboarding__text--red">настоящий тур добра</strong> — полный приключений, радости и новогодних чудес!
</br>
</br>
За 40 дней Доброфуры проедут через всю Россию и устроят Доброматчи <strong class="onboarding__text--red">на всех главных хоккейных площадках страны.</strong>
</br>
</br>
Одна стартует из Сочи 13 ноября,  другая — из Хабаровска
17 ноября. И уже спустя 40 дней, десятки тысяч километров, 18 городов и 20 Доброматчей они наконец-таки встретятся в Уфе, где устроят настоящий <strong>Новогодний Мишкопад!</strong>

`,
			image: 'assets/images/onboarding-image-1.png',
		},
		{
			title: 'Отправляй лучи добра',
			description: `В таком путешествии нашим Доброфурам просто необходима твоя поддержка. Моральной будет вполне достаточно.
<br>
<br>
<strong class="onboarding__text--red">Просто кликни по любой из Доброфур и отправишь в ее
поддержку «Луч Добра».</strong>
<br>
<br>
Активно поддерживай Доброфуры, <strong class="onboarding__text--red">отправляй больше</strong> <br>
<strong class="onboarding__text--red">«Лучей Добра»</strong> и за их благодарностью не заржавеет!
<br>
<br>
<strong>Чем больше «Лучей Добра» отправишь — тем выше шанс победить в Доброзыгрыше и получить Добромерч!</strong>`,
			image: 'assets/images/onboarding-image-2.png',
		},
		{
			title: 'Добро побеждает!',
			description: `Может Доброфурам и хватает моральной поддержки...
Но вот благотворительным организациям — нет.
<strong>Для них каждый рубль — это шанс спасти чью-то жизнь.</strong>
<br>
<br>
Именно по этому ДоброFON и Фонбет Чемпионат КХЛ организовали Настоящий тур добра. <strong>Мы перемножим количество болельщиков всех Доброматчей на 100, переведем число в рубли и распределим полученную сумму между всеми фондами, выбранными самими клубами КХЛ.</strong>
<br>
<br>
Не получится посетить Доброматч? <strong>Тогда поддержи фонд «Дорога Жизни»</strong> у нас на сайте. <strong class="onboarding__text--red">Крупнее поддержка — сильнее твой «Луч Добра» и выше вероятность на победу!</strong> `,
			image: 'assets/images/onboarding-image-3.png',
		},
	];

	slideChanged(index: number) {
		switch (index) {
			case 1:
				this.activeSlide = 2;
				this.btnName$.next('Понял!');
				break;
			case 2:
				this.activeSlide = 0;
				this.btnName$.next('Всё понял!');
				break;
			case 0:
				this.activeSlide = 1;
				this.btnName$.next('Рад познакомиться!');
				break;
		}
	}

	closeModal(index: number, block = true) {
		if (!block) {
			this.gameService.onBoarding = 'true';
			this.bsModelRef.hide();
			return;
		}
		this.slideChanged(index);
	}
}
