import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state';
import { RegistrationModel } from '../../../models/registration.model';
import { Registration } from '../../../state/auth.actions';

@Component({
	selector: 'app-smart-registration',
	template: `
		<app-registration
			[form]="form"
			(submitForm)="performRegistration()"></app-registration>
	`,
})
export class SmartRegistrationComponent implements OnInit {
	form: FormGroup;
	constructor(
		private fb: FormBuilder,
		private store: Store<State>
	) {}

	ngOnInit() {
		this.initForm();
	}

	initForm() {
		this.form = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.minLength(8)]],
			policy: [false, Validators.requiredTrue],
		});
	}

	performRegistration() {
		if (!this?.form.valid) {
			return;
		}
		const data: RegistrationModel = this.form.value;
		this.store.dispatch(new Registration(data));
	}
}
