import { Component, Input, ViewEncapsulation } from '@angular/core';
import { goodnessTemplate } from '../../../../../assets/mock-data';
import { UserModel } from '../../../auth/models/user-model.model';

@Component({
	selector: 'app-card-user-beam',
	template: ` <div class="card-user-beam">
		<div class="card-user-beam__top">
			<div class="card-user-beam__title">Ваш луч добра</div>
			<!--  добавить кнопке вопросу модификатор _white    -->
			<app-btn-question [content]="template"></app-btn-question>
		</div>
		<div class="card-user-beam__middle">
			<div class="card-user-beam__image">
				<img [src]="awardImage" alt="image" />
			</div>
			<div class="card-user-beam__status">
				<div class="card-user-beam__status-top">Cила Добра</div>
				<div class="card-user-beam__status-bottom">{{
					user.ray | transformNumber
				}}</div>
			</div>
		</div>
		<div class="card-user-beam__bottom"
			>Совершите донат в фонд «Дорога Жизни», чтобы увеличить силу Луча Добра и
			повысить вероятность получения Добромерча!
		</div>
	</div>`,
	styleUrls: ['./card-user-beam.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CardUserBeamComponent {
	@Input() user: UserModel;
	template = goodnessTemplate;

	get awardImage() {
		const ray = this.user.ray;
		const src = n => 'assets/images/powers/power' + n + '.png';
		if (ray === 1) {
			return src(1);
		} else if (ray >= 2 && ray < 6) {
			return src(2);
		} else if (ray >= 6 && ray < 11) {
			return src(6);
		} else if (ray >= 11 && ray < 21) {
			return src(11);
		} else if (ray >= 21 && ray < 51) {
			return src(21);
		} else if (ray >= 51 && ray < 101) {
			return src(51);
		} else if (ray >= 101) {
			return src(101);
		} else {
			return src(1);
		}
	}
}
