<a
	role="button"
	[routerLink]="link"
	class="wagon-change-button"
	[ngClass]="{
		east: type === 'east',
		'west _right': type === 'west',
		active: type === active
	}">
	<div class="wagon-change-button__text">доброфура</div>
	<img
		class="wagon-change-button__img"
		src="../../../../../assets/images/btn-fura.svg"
		alt="fura" />
	<div class="wagon-change-button__text">{{
		type === 'east' ? '«ВОСТОК»' : '«ЗАПАД»'
	}}</div>
</a>
