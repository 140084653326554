<div class="fund-support">
	<div class="fund-support__top">
		<div class="fund-support__texts">
			<div class="fund-support__title"
				>Поддержи фонд «Дорога жизни» и увеличь силу Луча добра</div
			>
			<div class="fund-support__text"
				>Сила луча добра увеличивается за каждые 100 рублей, отправленные в
				поддержку фонда</div
			>
		</div>
		<div class="fund-support__logo">
			<button
				[popover]="tooltip"
				[outsideClick]="true"
				containerClass="tooltip-custom"
				><img src="assets/images/fund-avatar.png" alt="logo"
			/></button>
		</div>
	</div>
	<div class="fund-support__bottom form-field form-group">
		<a
			target="_blank"
			href="https://www.doroga-zhizni.org/oferta.pdf"
			class="fund-support__link"
			>Договор пожертвования</a
		>
		<form
			[formGroup]="form"
			(ngSubmit)="submitForm.emit()"
			class="fund-support__form">
			<input
				formControlName="sum"
				min="1"
				step="1"
				type="number"
				placeholder="Ваша сумма"
				class="fund-support__input"
				appShowErrors />
			<button
				type="submit"
				[disabled]="!form.valid"
				class="fund-support__button"
				>Поддержать фонд</button
			>
			<a
				(click)="showHistory.emit()"
				role="button"
				class="fund-support__history"
				>История операций</a
			>
		</form>
	</div>
	<div class="fund-support__bottom _mobile">
		<form
			[formGroup]="form"
			(ngSubmit)="submitForm.emit()"
			class="fund-support__form">
			<div class="fund-support__bottom-row form-field form-group">
				<input
					formControlName="sum"
					type="number"
					min="1"
					step="1"
					placeholder="Ваша сумма"
					appShowErrors
					class="fund-support__input" />
				<a
					href="https://www.doroga-zhizni.org/oferta.pdf"
					target="_blank"
					class="fund-support__link"
					>Договор пожертвования</a
				>
			</div>
			<div class="fund-support__bottom-row">
				<button
					[disabled]="!form.valid"
					type="submit"
					class="fund-support__button"
					>Поддержать фонд</button
				>
				<a
					role="button"
					(click)="showHistory.emit()"
					class="fund-support__history"
					>История операций</a
				>
			</div>
		</form>
	</div>
</div>
<ng-template #tooltip><div [innerHtml]="fundTemplate"></div></ng-template>
