<div class="profile__item profile__awards">
	<div class="profile__awards-title">Мои награды</div>
	<div class="profile__awards-wrap" *ngIf="awardSlides?.length; else noAwards">
		<div class="profile__awards-slider">
			<swiper-container init="false" appSwiperSlider [config]="config">
				<swiper-slide
					class="profile__awards-item"
					*ngFor="let slide of awardSlides">
					<div class="profile__awards-award">
						<div class="profile__awards-award-image">
							<img
								[src]="'assets/images/awards/award-' + slide.awardID + '.png'"
								alt="ava" />
						</div>
						<div class="profile__awards-award-title">{{ slide.nameAward }}</div>
						<div class="profile__awards-award-text"
							>Добророзыгрыш {{ slide.rafflesID }}</div
						>
					</div>
				</swiper-slide>
			</swiper-container>
			<button id="profile-awards-prev" class="profile__awards-slider-nav _prev">
				<svg
					width="10"
					height="12"
					viewBox="0 0 10 12"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M1.81164 0.233258C2.15228 -0.0777525 2.70456 -0.077753 3.0452 0.233258L8.74452 5.43686C9.08516 5.74788 9.08516 6.25212 8.74452 6.56314L3.0452 11.7667C2.70456 12.0778 2.15228 12.0778 1.81164 11.7667L1.25548 11.259C0.91484 10.9479 0.91484 10.4437 1.25548 10.1327L5.16507 6.56314C5.50571 6.25212 5.50571 5.74788 5.16507 5.43686L1.25548 1.86732C0.91484 1.55631 0.914841 1.05206 1.25548 0.741046L1.81164 0.233258Z" />
				</svg>
			</button>
			<button id="profile-awards-next" class="profile__awards-slider-nav _next">
				<svg
					width="10"
					height="12"
					viewBox="0 0 10 12"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M1.81164 0.233258C2.15228 -0.0777525 2.70456 -0.077753 3.0452 0.233258L8.74452 5.43686C9.08516 5.74788 9.08516 6.25212 8.74452 6.56314L3.0452 11.7667C2.70456 12.0778 2.15228 12.0778 1.81164 11.7667L1.25548 11.259C0.91484 10.9479 0.91484 10.4437 1.25548 10.1327L5.16507 6.56314C5.50571 6.25212 5.50571 5.74788 5.16507 5.43686L1.25548 1.86732C0.91484 1.55631 0.914841 1.05206 1.25548 0.741046L1.81164 0.233258Z" />
				</svg>
			</button>
		</div>
		<button (click)="showAddress.emit()" class="profile__awards-btn"
			>Получить награды</button
		>
	</div>
	<ng-template #noAwards>
		<p class="profile__awards-no-awards"
			>Наград пока нет :( Отправляй Лучи добра и выигрывай!</p
		>
	</ng-template>
</div>
