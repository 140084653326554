import { GameActionTypes, GameActionUnion } from './game.actions';
import {
	ArticleData,
	ArticleFromServer,
	CityEvent,
	FundData,
	NewsData,
	NewsImage,
	Raffle,
	RaffleWinner,
} from '../models/game.model';
import { State } from '../../../state';

export interface GameState {
	allBank: number;
	isLoading: boolean;
	pastEvents: NewsData[];
	futureEvents: NewsData[];
	westEvents: CityEvent[];
	eastEvents: CityEvent[];
	currentArticle: ArticleFromServer;
	funds: FundData[];
	raffles: Raffle[];
	winners: RaffleWinner[];
	isEventActive: boolean;
}

export const initialState: GameState = {
	allBank: 0,
	isLoading: false,
	currentArticle: null,
	pastEvents: [],
	futureEvents: [],
	westEvents: [],
	eastEvents: [],
	funds: [],
	raffles: [],
	winners: [],
	isEventActive: false,
};
export function reducer(
	state = initialState,
	action: GameActionUnion
): GameState {
	switch (action.type) {
		case GameActionTypes.TOGGLE_ACTIVE_EVENT:
			return {
				...state,
				isEventActive: !state.isEventActive,
			};
		case GameActionTypes.GET_ALL_BANK:
			return {
				isLoading: true,
				...state,
			};
		case GameActionTypes.GET_ALL_BANK_SUCCESS:
			return {
				...state,
				isLoading: false,
				allBank: action.payload,
			};
		case GameActionTypes.GET_FUTURE_EVENTS:
			return {
				...state,
				isLoading: true,
			};
		case GameActionTypes.GET_FUTURE_EVENTS_SUCCESS:
			return {
				...state,
				futureEvents: action.payload,
			};
		case GameActionTypes.GET_PAST_EVENTS:
			return {
				...state,
				isLoading: true,
			};
		case GameActionTypes.GET_PAST_EVENTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				pastEvents: action.payload,
			};
		case GameActionTypes.GET_ARTICLE:
			return {
				...state,
				isLoading: true,
			};
		case GameActionTypes.GET_ARTICLE_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentArticle: action.payload,
			};
		case GameActionTypes.GET_EVENTS:
			return {
				...state,
				isLoading: true,
			};
		case GameActionTypes.GET_EVENTS_SUCCESS:
			return {
				...state,
				westEvents: action.payload.west,
				eastEvents: action.payload.east,
			};
		case GameActionTypes.GET_FUNDS:
			return {
				...state,
				isLoading: true,
			};
		case GameActionTypes.GET_FUNDS_SUCCESS:
			return {
				...state,
				isLoading: false,
				funds: action.payload,
			};
		case GameActionTypes.NEXT_RAFFLE:
			return {
				...state,
			};
		case GameActionTypes.GET_RAFFLES:
			return {
				...state,
				isLoading: true,
			};
		case GameActionTypes.GET_RAFFLES_SUCCESS:
			return {
				...state,
				isLoading: false,
				raffles: action.payload,
			};
		case GameActionTypes.GET_WINNERS_FOR_RAFFLE:
			return {
				...state,
				isLoading: true,
			};
		case GameActionTypes.GET_WINNERS_FOR_RAFFLE_SUCCESS:
			return {
				...state,
				isLoading: false,
				winners: action.payload,
			};
		default:
			return state;
	}
}

export const selectWestEvents = (state: State): CityEvent[] =>
	state?.game?.westEvents;
export const selectFutureEvents = (state: State): NewsData[] =>
	state?.game?.futureEvents;
export const selectPastEvents = (state: State): NewsData[] =>
	state?.game?.pastEvents;
export const selectEastEvents = (state: State): CityEvent[] =>
	state?.game?.eastEvents;
export const selectFunds = (state: State): FundData[] => state?.game?.funds;
export const selectRaffles = (state: State): Raffle[] => state?.game?.raffles;
export const selectIsEventActive = (state: State): boolean =>
	state?.game?.isEventActive;
export const selectWinners = (state: State): RaffleWinner[] =>
	state?.game?.winners;
export const selectCurrentArticle = (state: State): ArticleData =>
	state?.game?.currentArticle?.news;
export const selectCurrentArticleImages = (state: State): NewsImage[] =>
	state?.game?.currentArticle?.newsImages;
