<section class="report">
	<div class="container">
		<div
			class="report__row"
			*ngIf="(pastEvents$ | async)?.length > 0; else noEvents">
			<app-card-report
				*ngFor="let card of pastEvents$ | async"
				[reportCard]="card"
				[type]="type"></app-card-report>
		</div>
		<ng-template #noEvents
			><p class="report__no-awards"
				>Доброфура «<span
					[innerText]="type === 'east' ? 'Восток' : 'Запад'"></span
				>» уже в пути. Скоро мы расскажем вам о первом доброматче!</p
			>
		</ng-template>
	</div>
	<app-banner [sum]="allBank$ | async"></app-banner>
	<app-moments-slider></app-moments-slider>
</section>
