<div class="card-city">
	<button class="card-city__close">
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2.45882 2.45882C3.07058 1.84706 4.06243 1.84706 4.67419 2.45882L8 5.78463L11.3258 2.45882C11.9376 1.84706 12.9294 1.84706 13.5412 2.45882C14.1529 3.07058 14.1529 4.06243 13.5412 4.67419L10.2154 8L13.5412 11.3258C14.1529 11.9376 14.1529 12.9294 13.5412 13.5412C12.9294 14.1529 11.9376 14.1529 11.3258 13.5412L8 10.2154L4.67419 13.5412C4.06243 14.1529 3.07058 14.1529 2.45882 13.5412C1.84706 12.9294 1.84706 11.9376 2.45882 11.3258L5.78463 8L2.45882 4.67419C1.84706 4.06243 1.84706 3.07058 2.45882 2.45882ZM3.5665 12.4335L3.6292 12.3708L3.5665 12.4335Z" />
		</svg>
	</button>
	<div class="card-city__left">
		<img src="../../../../../assets/images/city-card-image.jpg" alt="image" />
	</div>
	<div class="card-city__right">
		<div class="card-city__title">Санкт-Петербург</div>
		<div class="card-city__list">
			<div class="card-city__list-item">
				<img src="../../../../../assets/images/location.svg" alt="location" />
				«Татнефть Арена»
			</div>
			<div class="card-city__list-item">
				<img src="../../../../../assets/images/calendar.svg" alt="calendar" />
				20 ноября, 2023
			</div>
			<div class="card-city__list-item">
				<img src="../../../../../assets/images/clock.svg" alt="clock" />
				19:00
			</div>
		</div>
		<button class="card-city__button">Подробнее</button>
		<div class="card-city__logo">
			<img src="../../../../../assets/images/tiger.jpg" alt="logo" />
		</div>
	</div>
</div>
