import { Awards } from '../app/modules/profile/components/profile-awards/profile-awards.component';
import { OperationItem } from '../app/modules/profile/components/operation-history/operation-history.component';
import { AwardItem } from '../app/modules/profile/models/profile.model';
import { DateTime } from 'luxon';

export interface RaffleData {
	id: number;
	expiredAt: DateTime;
}

export const mockRafflesData: RaffleData[] = [
	{
		id: 1,
		expiredAt: DateTime.fromISO('2023-11-16T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
	{
		id: 2,
		expiredAt: DateTime.fromISO('2023-11-21T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
	{
		id: 3,
		expiredAt: DateTime.fromISO('2023-11-25T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
	{
		id: 4,
		expiredAt: DateTime.fromISO('2023-11-29T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
	{
		id: 5,
		expiredAt: DateTime.fromISO('2023-12-03T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
	{
		id: 6,
		expiredAt: DateTime.fromISO('2023-12-07T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
	{
		id: 7,
		expiredAt: DateTime.fromISO('2023-12-11T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
	{
		id: 8,
		expiredAt: DateTime.fromISO('2023-12-15T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
	{
		id: 9,
		expiredAt: DateTime.fromISO('2023-12-19T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
	{
		id: 10,
		expiredAt: DateTime.fromISO('2023-12-23T18:00:00+03:00').setZone(
			'Europe/Moscow'
		),
	},
];

export const historyItems: OperationItem[] = [
	{
		date: new Date(),
		value: 1000,
		points: 1000,
	},
	{
		date: new Date(),
		value: 10000,
		points: 10000,
	},
	{
		date: new Date(),
		value: 500000,
		points: 500000,
	},
	{
		date: new Date(),
		value: 2500,
		points: 2500,
	},
	{
		date: new Date(),
		value: 36800,
		points: 36800,
	},
	{
		date: new Date(),
		value: 789456,
		points: 789456,
	},
];

export const awardSlides: AwardItem[] = [
	{
		awardID: 1,
		nameAward: Awards.STICKERPACK,
		rafflesID: 1,
	},
	{
		awardID: 2,
		nameAward: Awards.SOCKS,
		rafflesID: 1,
	},
	{
		awardID: 3,
		nameAward: Awards.MITTENS,
		rafflesID: 1,
	},
	{
		awardID: 4,
		nameAward: Awards.SCARF,
		rafflesID: 1,
	},
	{
		awardID: 5,
		nameAward: Awards.JUMPER,
		rafflesID: 1,
	},
];

export const ratingTemplate = `
      <div class="rating-popup__table">
        <div class="rating-popup__row">
          <div class="rating-popup__col _bold">Добряк:</div>
          <div class="rating-popup__col">Все участники</div>
        </div>
        <div class="rating-popup__row">
          <div class="rating-popup__col _bold">Доброволец:</div>
          <div class="rating-popup__col">топ-75%&nbsp;участников</div>
        </div>
        <div class="rating-popup__row">
          <div class="rating-popup__col _bold">Доброхот:</div>
          <div class="rating-popup__col">топ-50%&nbsp;участников</div>
        </div>
        <div class="rating-popup__row">
          <div class="rating-popup__col _bold">Добрыня:</div>
          <div class="rating-popup__col">топ-25%&nbsp;участников</div>
        </div>
        <div class="rating-popup__row">
          <div class="rating-popup__col _bold">Доброфурщик:</div>
          <div class="rating-popup__col">топ-10%&nbsp;участников</div>
        </div>
    </div>`;

export const goodnessTemplate = `
      <div class="tooltip-custom__text">
        <span>Сила Добра</span> = количество
        отправляемых Лучей Добра
        при каждом клике по Доброфуре.
      </div>
      <div class="tooltip-custom__text">
        За каждые <span>100 рублей</span> поддержки
        в счёт фонда «Дорога Жизни» сила
        вашего Луча Добра увеличится на +1.
      </div>
      <div class="tooltip-custom__text">
        <span>Чем выше Сила Добра</span> — тем проще
        заработать высокий ранг и выиграть
        ценный Добромерч!
      </div>
    `;
export const fundTemplate = `
      <div class="tooltip-custom__text">
        <span>Фонд «Дорога Жизни»</span> делает доступной медицину для детей-сирот в регионах.
      </div>
      <h4 class="tooltip-custom__title">Цели фонда</h4>
      <ul>
      <li class="tooltip-custom__list-item tooltip-custom__text">
       Обеспечить детей-сирот,  живущих в отдаленных регионах своевременной и необходимой медицинской помощью;
</li>
      <li class="tooltip-custom__list-item tooltip-custom__text">
Изменить и улучшить качество жизни детей с ОВЗ, живущих в сиротских учреждениях; </li>
      <li class="tooltip-custom__list-item tooltip-custom__text">
Сделать так, чтобы детям, в жизни которых и так много проблем и сложностей, было легче жить, чтобы детство было у каждого ребенка!</li>
</ul>
    `;

export const rangTemplate = `
			<div class="rang-popup__text">
				<span>Чем выше ваш ранг</span> — тем более ценный Добромерч вы можете
				выиграть, и тем выше шанс на победу в Доброзыгрыше!
			</div>
    `;
