import {
	Directive,
	ElementRef,
	OnDestroy,
	OnInit,
	Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Directive({
	selector: '[appShowErrors]',
})
export class ShowErrorsDirective implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
		private control: NgControl
	) {}

	ngOnInit() {
		this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			const control = this.control.control;
			const errors = control.errors;
			const hasErrors = errors && (control.dirty || control.touched);

			// Очистка предыдущих сообщений об ошибках
			const formField = this.el.nativeElement.closest('.form-field');
			const errorMessages = formField.getElementsByClassName('form-error');
			while (errorMessages.length > 0) {
				errorMessages[0].parentNode.removeChild(errorMessages[0]);
			}

			// Показ новых сообщений об ошибках
			if (hasErrors) {
				Object.keys(errors).forEach(key => {
					const errorMessage = this.getErrorMessage(key, errors[key]);
					const errorNode = this.renderer.createElement('div');
					this.renderer.addClass(errorNode, 'form-error');
					const text = this.renderer.createText(errorMessage);
					this.renderer.appendChild(errorNode, text);
					this.renderer.appendChild(formField, errorNode);
				});
			}
		});
	}

	getErrorMessage(errorKey: string, errorValue: any): string {
		const messages = {
			required: 'Это поле обязательно',
			minlength: `Минимальная длина пароля ${errorValue.requiredLength}`,
			min: `Минимальная сумма ${errorValue.min}`,
			max: `Максимальная сумма ${errorValue.max}`,
		};

		return messages[errorKey] || 'Неверное значение';
	}
	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
