import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../state';
import { GetArticle } from '../../state/game.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-article-report-page',
	template: `<app-smart-article-report></app-smart-article-report>`,
})
export class ArticleReportPageComponent implements OnInit {
	constructor(
		private store: Store<State>,
		private route: ActivatedRoute
	) {}
	ngOnInit() {
		this.route.params.subscribe(params => {
			if (Number(params['id'])) {
				this.store.dispatch(new GetArticle(Number(params['id'])));
			}
		});
	}
}
