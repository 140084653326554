import { Pipe, PipeTransform } from '@angular/core';
import { EventObj } from '../../../../assets/cities.data';
import { DateTime } from 'luxon';

@Pipe({
	name: 'filterDate',
})
export class FilterDatePipe implements PipeTransform {
	transform(events: EventObj[]): EventObj[] {
		const now = DateTime.local();

		return events.map(item => {
			const itemDate = DateTime.fromJSDate(item.date);
			const isActive = now.day >= itemDate.day && now.month >= itemDate.month;

			return {
				...item,
				active: isActive,
			};
		});
	}
}
