import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../state';
import { VkAuth } from '../state/auth.actions';
import { VkAuthReq } from '../models/user-model.model';
import { environment } from '../../../../environments/environment';
const { Connect, Config, ConnectEvents } = window['SuperAppKit'];

@Directive({
	selector: '[appVkOneTap]',
})
export class VkOneTapDirective implements AfterViewInit {
	constructor(
		private el: ElementRef,
		private store: Store<State>
	) {}

	ngAfterViewInit(): void {
		Config.init({
			appId: 51784510,
		});

		const oneTapButton = Connect.buttonOneTapAuth({
			callback: (event: any) => {
				const { type } = event;
				if (!type) {
					return;
				}

				switch (type) {
					case ConnectEvents.OneTapAuthEventsSDK.LOGIN_SUCCESS: // = 'VKSDKOneTapAuthLoginSuccess'
						console.log('callback data from auth', event);
						this.store.dispatch(new VkAuth(event.payload as VkAuthReq));
						return;
					// Для этих событий нужно открыть полноценный VK ID чтобы
					// пользователь дорегистрировался или подтвердил телефон
					case ConnectEvents.OneTapAuthEventsSDK.FULL_AUTH_NEEDED: //  = 'VKSDKOneTapAuthFullAuthNeeded'
					case ConnectEvents.OneTapAuthEventsSDK.PHONE_VALIDATION_NEEDED: // = 'VKSDKOneTapAuthPhoneValidationNeeded'
					case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN: {
						// = 'VKSDKButtonOneTapAuthShowLogin'
						// url - строка с url, на который будет произведён редирект после авторизации.
						// state - состояние вашего приложение или любая произвольная строка, которая будет добавлена к url после авторизации.
						return Connect.redirectAuth({
							url: 'https://xn--80abi4bblbxi.xn--p1ai/west-wagon',
						});
					}
					// Пользователь перешел по кнопке "Войти другим способом"
					case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN_OPTIONS: {
						// = 'VKSDKButtonOneTapAuthShowLoginOptions'
						// Параметр screen: phone позволяет сразу открыть окно ввода телефона в VK ID
						// Параметр url: ссылка для перехода после авторизации. Должен иметь https схему. Обязательный параметр.

						return Connect.redirectAuth({
							screen: 'phone',
							url: 'https://xn--80abi4bblbxi.xn--p1ai/west-wagon',
						});
					}
				}

				return;
			},
			// Не обязательный параметр с настройками отображения OneTap
			options: {
				showAlternativeLogin: false,
				showAgreements: false,
				displayMode: 'phone_name',
				langId: 0,
				buttonSkin: 'primary',
				buttonStyles: {
					borderRadius: 8,
					height: 50,
				},
			},
		});

		this.el.nativeElement.appendChild(oneTapButton.getFrame());
	}
}
