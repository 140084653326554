import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Mousewheel, Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { Raffle, RaffleWinner } from '../../models/game.model';
import { AwardItem } from '../../../profile/models/profile.model';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../../state';
import { GetWinnersForRaffle } from '../../state/game.actions';
import { OnboardingComponent } from '../onboarding/onboarding.component';

@Component({
	selector: 'app-merchandise-slider-wrapper',
	templateUrl: './merchandise-slider-wrapper.component.html',
	styleUrls: ['./merchandise-slider-wrapper.component.scss'],
})
export class MerchandiseSliderWrapperComponent implements OnChanges {
	@Input() raffleSlides: Raffle[];
	@Input() winSlides: RaffleWinner[];
	activeSLide$ = new BehaviorSubject<number>(0);
	constructor(private store: Store<State>) {}
	public config: SwiperOptions = {
		modules: [Navigation, Mousewheel],
		autoHeight: true,
		slidesPerView: 3,
		slideActiveClass: '_active',
		slideToClickedSlide: true,
		centeredSlides: true,
		speed: 500,
		mousewheel: true,
		eventsPrefix: 'raffle',
		spaceBetween: 7,
		preventInteractionOnTransition: true,
		allowTouchMove: true,
		breakpoints: {
			'1280': {
				slidesPerView: 'auto',
				spaceBetween: 10,
			},
		},
		navigation: {
			enabled: true,
			nextEl: '#fund-next',
			prevEl: '#fund-prev',
			disabledClass: '_disabled',
		},
	};

	ngOnChanges(changes: SimpleChanges) {
		if ('raffleSlides' in changes) {
			const raffles = changes['raffleSlides'].currentValue as Raffle[];
			if (raffles?.length > 0) {
				const id = raffles[0].id;
				this.store.dispatch(new GetWinnersForRaffle(id));
			}
		}
	}

	onRaffleChange(id: number) {
		console.log('slide changed', id);

		this.store.dispatch(new GetWinnersForRaffle(id));
	}
	activeSlide(value: number) {
		console.log('activeSlide', value);
	}
}
