<!-- единственное надо подключить какой то кастомный скролл плагин в блок operation-history__body. и если что я его тоже застилю. -->
<div class="operation-history">
	<button
		class="operation-history__close"
		type="button"
		(click)="bsModal.hide()">
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2.45882 2.45882C3.07058 1.84706 4.06243 1.84706 4.67419 2.45882L8 5.78463L11.3258 2.45882C11.9376 1.84706 12.9294 1.84706 13.5412 2.45882C14.1529 3.07058 14.1529 4.06243 13.5412 4.67419L10.2154 8L13.5412 11.3258C14.1529 11.9376 14.1529 12.9294 13.5412 13.5412C12.9294 14.1529 11.9376 14.1529 11.3258 13.5412L8 10.2154L4.67419 13.5412C4.06243 14.1529 3.07058 14.1529 2.45882 13.5412C1.84706 12.9294 1.84706 11.9376 2.45882 11.3258L5.78463 8L2.45882 4.67419C1.84706 4.06243 1.84706 3.07058 2.45882 2.45882ZM3.5665 12.4335L3.6292 12.3708L3.5665 12.4335Z" />
		</svg>
	</button>
	<div class="operation-history__title">История операций</div>
	<div class="operation-history__head">
		<div class="operation-history__block">Сумма</div>
		<div class="operation-history__block">Дата</div>
		<div class="operation-history__block">Сила луча</div>
	</div>
	<div class="operation-history__body" *ngIf="bankHistory?.length">
		<div class="operation-history__row" *ngFor="let operation of bankHistory">
			<div class="operation-history__col"
				>{{ operation.score | transformNumber }} ₽</div
			>
			<!--			<div class="operation-history__col">1 000 000 ₽</div>-->
			<div class="operation-history__col">{{
				operation.createdAt | date: 'd.M.yy'
			}}</div>
			<div class="operation-history__col"
				>+ {{ operation.score | rayPower | transformNumber }}</div
			>
		</div>
	</div>
</div>
