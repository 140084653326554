import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ArticleData, NewsImage } from '../../models/game.model';
import { SwiperOptions } from 'swiper/types';
import { Pagination, Mousewheel } from 'swiper/modules';
import {
	Image,
	ModalGalleryRef,
	ModalGalleryService,
} from '@ks89/angular-modal-gallery';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
	selector: 'app-article-report-container',
	templateUrl: './article-report-container.component.html',
	styleUrls: ['./article-report-container.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ArticleReportContainerComponent implements OnDestroy {
	@Input() article: ArticleData;
	@Input() articleImages: { original: NewsImage[]; gallery: Image[] };
	@Input() showArticleImages: boolean;
	gallerySubscription: Subscription;
	activeSlide$ = new BehaviorSubject<number>(0);
	constructor(private modalGalleryService: ModalGalleryService) {}
	public config: SwiperOptions = {
		modules: [Pagination, Mousewheel],
		slidesPerView: 1,
		speed: 500,
		centeredSlides: true,
		preventInteractionOnTransition: true,
		allowTouchMove: true,
		preventClicks: true,
		spaceBetween: 15,
		pagination: {
			enabled: true,
			clickable: true,
		},
	};
	get slider() {
		try {
			const jsonString = this.article.slider.replace(/^'|'$/g, '');
			const imageObject = JSON.parse(jsonString);
			return Object.values(imageObject);
		} catch (error) {
			return [];
		}
	}

	ngOnDestroy() {
		this.gallerySubscription?.unsubscribe();
		this.activeSlide$.complete();
	}

	onShow(id: number, index: number): void {
		const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
			id,
			images: this.article.gallery,
			currentImage: this.article.gallery[index],
		}) as ModalGalleryRef;
		this.gallerySubscription = dialogRef.show$.subscribe(data => {
			if (typeof data.result !== 'number') {
				return;
			}
			this.activeSlide$.next(data.result - 1 <= 0 ? 0 : data.result - 1);
		});
	}
}
