import { Component, Input } from '@angular/core';
import { NavBtnData } from '../../../../app-routing.module';

@Component({
	selector: 'app-nav-button-right',
	template: `
		<a [routerLink]="data?.link ? data.link : null" class="nav-btn">
			<ng-container *ngIf="data">
				<div class="nav-btn__decor _up"><div></div><div></div></div>
				<div class="nav-btn__arrow _up"
					><img src="assets/images/arrow.svg" alt="arrow"
				/></div>
				<p class="nav-btn__text">{{ data.text }}</p>
				<div class="nav-btn__arrow _down"
					><img src="assets/images/arrow.svg" alt="arrow"
				/></div>
				<div class="nav-btn__decor _down"><div></div><div></div></div>
			</ng-container>
		</a>
	`,
	styleUrls: ['./nav-button-right.component.scss'],
})
export class NavButtonRightComponent {
	@Input() data: NavBtnData;
}
