import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, NgOptimizedImage } from '@angular/common';
import { CardCityDescriptionComponent } from './components/card-city-description/card-city-description.component';
import { CardReportComponent } from './components/card-report/card-report.component';
import { CardFundComponent } from './components/card-fund/card-fund.component';
import { CardUserBeamComponent } from './components/card-user-beam/card-user-beam.component';
import { BtnQuestionComponent } from './components/btn-question/btn-question.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavButtonLeftComponent } from './components/nav-button-left/nav-button-left.component';
import { NavButtonRightComponent } from './components/nav-button-right/nav-button-right.component';
import { HeaderComponent } from './components/header/header.component';
import { SmartWagonScreenComponent } from './components/smart/smart-wagon-screen/smart-wagon-screen.component';
import { WagonScreenComponent } from './components/wagon-screen/wagon-screen.component';
import { BtnUpPageComponent } from './components/btn-up-page/btn-up-page.component';
import { SmartProfileAvatarComponent } from './components/smart/smart-profile-avatar/smart-profile-avatar.component';
import { ProfileAvatarComponent } from './components/profile-avatar/profile-avatar.component';
import { WagonChangeButtonComponent } from './components/wagon-change-button/wagon-change-button.component';
import { SmartBeamCounterComponent } from './components/smart/smart-beam-counter/smart-beam-counter.component';
import { BeamCounterComponent } from './components/beam-counter/beam-counter.component';
import { SmartFundSupportComponent } from './components/smart/smart-fund-support/smart-fund-support.component';
import { FundSupportComponent } from './components/fund-support/fund-support.component';
import { FundSliderContainerComponent } from './components/fund-slider-container/fund-slider-container.component';
import { MerchandiseSliderWrapperComponent } from './components/merchandise-slider-wrapper/merchandise-slider-wrapper.component';
import { AuthModule } from '../auth/auth.module';
import { HeroContainerComponent } from './components/hero-container/hero-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SmartBroadcastContainerComponent } from './components/smart/smart-broadcast-container/smart-broadcast-container.component';
import { ArticleReportPageComponent } from './pages/article-report-page/article-report-page.component';
import { AspectRatioDirective } from './directives/aspect-ratio.directive';
import { SmartHeroContainerComponent } from './components/smart/smart-hero-container/smart-hero-container.component';
import { SwiperSliderDirective } from './directives/swiper-slider.directive';
import { MerchandiseSliderContainerComponent } from './components/merchandise-slider-container/merchandise-slider-container.component';
import { TransformNumberPipe } from './pipes/transform-number.pipe';
import { TruckContainerComponent } from './components/truck-container/truck-container.component';
import { EastPathComponent } from './components/east-path/east-path.component';
import { WestPathComponent } from './components/west-path/west-path.component';
import { ClickAnimationDirective } from './directives/click-animation.directive';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterDatePipe } from './pipes/filter-date.pipe';
import { SmartReportContainerComponent } from './components/smart/smart-report-container/smart-report-container.component';
import { BannerComponent } from './components/banner/banner.component';
import { MomentsSliderComponent } from './components/moments-slider/moments-slider.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FotoGalleryComponent } from './components/foto-gallery/foto-gallery.component';
import { SmartArticleReportComponent } from './components/smart/smart-article-report/smart-article-report.component';
import { ArticleReportContainerComponent } from './components/article-report-container/article-report-container.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { RayPowerPipe } from './pipes/ray-power.pipe';
import { PreventDoubleTapZoomDirective } from './directives/prevent-double-tap-zoom.directive';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { AddGalleryPipe } from './pipes/add-gallery.pipe';
import { ConvertToImagePipe } from './pipes/convert-to-image.pipe';

@NgModule({
	declarations: [
		CardCityDescriptionComponent,
		CardReportComponent,
		CardFundComponent,
		CardUserBeamComponent,
		BtnQuestionComponent,
		FooterComponent,
		NavButtonLeftComponent,
		NavButtonRightComponent,
		HeaderComponent,
		SmartWagonScreenComponent,
		WagonScreenComponent,
		BtnUpPageComponent,
		SmartProfileAvatarComponent,
		ProfileAvatarComponent,
		WagonChangeButtonComponent,
		SmartBeamCounterComponent,
		BeamCounterComponent,
		SmartFundSupportComponent,
		FundSupportComponent,
		FundSliderContainerComponent,
		MerchandiseSliderWrapperComponent,
		HeroContainerComponent,
		SmartBroadcastContainerComponent,
		ArticleReportPageComponent,
		AspectRatioDirective,
		SmartHeroContainerComponent,
		SwiperSliderDirective,
		MerchandiseSliderContainerComponent,
		TransformNumberPipe,
		TruckContainerComponent,
		EastPathComponent,
		WestPathComponent,
		ClickAnimationDirective,
		OnboardingComponent,
		FilterDatePipe,
		SmartReportContainerComponent,
		BannerComponent,
		MomentsSliderComponent,
		FotoGalleryComponent,
		SmartArticleReportComponent,
		ArticleReportContainerComponent,
		ReversePipe,
		RayPowerPipe,
		PreventDoubleTapZoomDirective,
		AddGalleryPipe,
		ConvertToImagePipe,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [DecimalPipe],
	exports: [
		FooterComponent,
		NavButtonLeftComponent,
		NavButtonRightComponent,
		HeaderComponent,
		SmartWagonScreenComponent,
		BtnUpPageComponent,
		WagonScreenComponent,
		TransformNumberPipe,
		BtnQuestionComponent,
		SwiperSliderDirective,
		FilterDatePipe,
		SmartReportContainerComponent,
		ReversePipe,
		RayPowerPipe,
	],
	imports: [
		CommonModule,
		AuthModule,
		ReactiveFormsModule,
		RouterLink,
		RouterLinkActive,
		TooltipModule,
		PopoverModule,
		GalleryModule,
		NgOptimizedImage,
	],
})
export class SharedModule {}
