import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
	name: 'transformNumber',
})
export class TransformNumberPipe implements PipeTransform {
	constructor(private decimalPipe: DecimalPipe) {}

	transform(value: number) {
		return this.decimalPipe.transform(value, '1.0-0').replace(/,/g, ' ');
	}
}
