import { Component, Input, OnDestroy } from '@angular/core';
import { NewsImage } from '../../models/game.model';
import {
	Image,
	ModalGalleryRef,
	ModalGalleryService,
} from '@ks89/angular-modal-gallery';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-foto-gallery',
	templateUrl: './foto-gallery.component.html',
	styleUrls: [
		'../article-report-container/article-report-container.component.scss',
	],
})
export class FotoGalleryComponent implements OnDestroy {
	@Input() gallery: Image[];
	@Input() set images(images: NewsImage[]) {
		this.allImages = images;
		this.imagesToShow = images.slice(0, 6);
	}
	imagesToShow: NewsImage[];
	allImages: NewsImage[];
	gallerySubscription: Subscription;

	constructor(private modalGalleryService: ModalGalleryService) {}

	ngOnDestroy() {
		this.gallerySubscription?.unsubscribe();
	}

	moreImages() {
		const currentLength = this.imagesToShow.length;
		if (this.allImages.length <= currentLength) {
			return;
		}

		const numberOfItems = currentLength + 6;
		this.imagesToShow.push(
			...this.allImages.slice(currentLength, numberOfItems)
		);
	}

	onShow(id: number, index: number): void {
		const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
			id,
			images: this.gallery,
			currentImage: this.gallery[index],
			libConfig: {
				enableCloseOutside: true,
				dotsConfig: {
					visible: false,
				},
				previewConfig: {
					visible: true,
					size: {
						width: 'auto',
						height: '30px',
					},
				},
			},
		}) as ModalGalleryRef;
		this.gallerySubscription = dialogRef.show$.subscribe(data => {
			if (typeof data.result !== 'number') {
				return;
			}
		});
	}
}
