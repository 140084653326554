import { Action } from '@ngrx/store';
import { LoginModel } from '../models/login.model';
import { AddressData, UserModel, VkAuthReq } from '../models/user-model.model';
import { RegistrationModel } from '../models/registration.model';
import { PaymentItem } from '../../profile/models/profile.model';

export enum AuthActionTypes {
	CHANGE_AVATAR = '[Auth] Change avatar',
	DONATE = '[Auth] Donate',
	RESET_PASSWORD = '[Auth] User reset password',
	GET_BANK_HISTORY = '[Auth] Get user bank history',
	GET_BANK_HISTORY_SUCCESS = '[Auth] Get user bank history success',
	GET_USER = '[Auth] Get user',
	UPDATE_USER = '[Auth] Update user',
	SEND_ADDRESS = '[Auth] Send user address',
	GET_USER_SUCCESS = '[Auth] Get user successful',
	VK_AUTH = '[Auth] Auth over vk api',
	LOG_IN = '[Auth] Logging in',
	LOG_IN_SUCCESS = '[Auth] Login is successful',
	LOG_IN_FAILURE = '[Auth] Login is failed',
	REGISTRATION = '[Auth] Registration in',
	REGISTRATION_SUCCESS = '[Auth] Registration is successful',
	REGISTRATION_FAILURE = '[Auth] Registration is failed',
	LOG_OUT = '[Auth] Logout',
	LOG_OUT_SUCCESS = '[Auth] Logout is successful',
	LOG_OUT_FAILURE = '[Auth] Login is failed',
}

export class Donate implements Action {
	readonly type = AuthActionTypes.DONATE;
	constructor(public payload: { score: number; invoiceID: string }) {}
}

export class ChangeAvatar implements Action {
	readonly type = AuthActionTypes.CHANGE_AVATAR;
	constructor(public payload: number) {}
}

export class ResetPassword implements Action {
	readonly type = AuthActionTypes.RESET_PASSWORD;
	constructor(public payload: string) {}
}

export class VkAuth implements Action {
	readonly type = AuthActionTypes.VK_AUTH;
	constructor(public payload: VkAuthReq) {}
}

export class GetUser implements Action {
	readonly type = AuthActionTypes.GET_USER;
}

export class GetUserSuccess implements Action {
	readonly type = AuthActionTypes.GET_USER_SUCCESS;
	constructor(public payload: UserModel) {}
}

export class GetBankHistory implements Action {
	readonly type = AuthActionTypes.GET_BANK_HISTORY;
}

export class GetBankHistorySuccess implements Action {
	readonly type = AuthActionTypes.GET_BANK_HISTORY_SUCCESS;
	constructor(public payload: PaymentItem[]) {}
}

export class UpdateUser implements Action {
	readonly type = AuthActionTypes.UPDATE_USER;
}

export class SendUserData implements Action {
	readonly type = AuthActionTypes.SEND_ADDRESS;
	constructor(public payload: AddressData) {}
}

export class LogIn implements Action {
	readonly type = AuthActionTypes.LOG_IN;

	constructor(public payload: LoginModel) {}
}

export class LogInSuccess implements Action {
	readonly type = AuthActionTypes.LOG_IN_SUCCESS;

	constructor(public payload: UserModel) {}
}

export class LogInFailure implements Action {
	readonly type = AuthActionTypes.LOG_IN_FAILURE;
}

export class Registration implements Action {
	readonly type = AuthActionTypes.REGISTRATION;

	constructor(public payload: RegistrationModel) {}
}

export class RegistrationSuccess implements Action {
	readonly type = AuthActionTypes.REGISTRATION_SUCCESS;

	constructor(public payload: UserModel) {}
}

export class RegistrationFailure implements Action {
	readonly type = AuthActionTypes.REGISTRATION_FAILURE;
}

export class LogOut implements Action {
	readonly type = AuthActionTypes.LOG_OUT;
}

export class LogOutSuccess implements Action {
	readonly type = AuthActionTypes.LOG_OUT_SUCCESS;
}

export type AuthActionUnion =
	| ChangeAvatar
	| VkAuth
	| Donate
	| ResetPassword
	| SendUserData
	| UpdateUser
	| GetBankHistory
	| GetBankHistorySuccess
	| GetUser
	| GetUserSuccess
	| LogIn
	| LogInSuccess
	| LogInFailure
	| Registration
	| RegistrationSuccess
	| RegistrationFailure
	| LogOut
	| LogOutSuccess;
