import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleReportPageComponent } from './modules/shared/pages/article-report-page/article-report-page.component';
import { canActivate } from './modules/auth/guards/auth.guard';

export interface NavBtnData {
	text: string;
	link: string;
}
export interface RouteData {
	left: NavBtnData;
	right: NavBtnData;
	title: string;
}

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'west-wagon',
	},
	{
		path: 'west-report',
		pathMatch: 'full',
		loadChildren: () =>
			import('./modules/west-report/west-report.module').then(
				m => m.WestReportModule
			),
		data: {
			left: {
				link: 'east-report',
				text: 'ДОБРАЛИСЬ',
			},
			right: {
				link: 'west-wagon',
				text: 'ЗАПАД',
			},
			animation: 'west-report',
			title: 'Доброфура «Запад» уже добралась',
		},
	},
	{
		path: 'west-wagon',
		pathMatch: 'full',
		loadChildren: () =>
			import('./modules/west-wagon/west-wagon.module').then(
				m => m.WestWagonModule
			),
		data: {
			left: {
				link: 'west-report',
				text: 'ДОБРАЛИСЬ',
			},
			right: {
				link: 'destination',
				text: 'ДОБЕРЕМСЯ',
			},
			animation: 'west-wagon',
			title: 'Доброфура «Запад»',
		},
	},
	{
		path: 'destination',
		pathMatch: 'full',
		loadChildren: () =>
			import('./modules/destination/destination.module').then(
				m => m.DestinationModule
			),
		data: {
			left: {
				link: 'west-wagon',
				text: 'ЗАПАД',
			},
			right: {
				link: 'east-wagon',
				text: 'ВОСТОК',
			},
			animation: 'destination',
			title: 'Собрано в пути:',
		},
	},
	{
		path: 'east-wagon',
		pathMatch: 'full',
		loadChildren: () =>
			import('./modules/east-wagon/east-wagon.module').then(
				m => m.EastWagonModule
			),
		data: {
			left: {
				link: 'destination',
				text: 'ДОБЕРЕМСЯ',
			},
			right: {
				link: 'east-report',
				text: 'ДОБРАЛИСЬ',
			},
			animation: 'east-wagon',
			title: 'Доброфура «Восток»',
		},
	},
	{
		path: 'east-report',
		pathMatch: 'full',
		loadChildren: () =>
			import('./modules/east-report/east-report.module').then(
				m => m.EastReportModule
			),
		data: {
			left: {
				link: 'east-wagon',
				text: 'ВОСТОК',
			},
			right: {
				link: 'west-report',
				text: 'ДОБРАЛИСЬ',
			},
			animation: 'east-report',
			title: 'Доброфура «Восток» уже добралась',
		},
	},
	{
		path: 'future-events/:id',
		pathMatch: 'full',
		component: ArticleReportPageComponent,
		data: {
			left: {
				link: 'destination',
				text: 'НАЗАД',
			},
			animation: 'east-report-article',
			title: 'Предстоящие мероприятия',
		},
	},
	{
		path: 'past-events-east/:id',
		pathMatch: 'full',
		component: ArticleReportPageComponent,
		data: {
			left: {
				link: 'east-report',
				text: 'НАЗАД',
			},
			animation: 'west-report-article',
			title: 'Прошедшие мероприятия',
		},
	},
	{
		path: 'past-events-west/:id',
		pathMatch: 'full',
		component: ArticleReportPageComponent,
		data: {
			left: {
				link: 'west-report',
				text: 'НАЗАД',
			},
			animation: 'west-report-article',
			title: 'Прошедшие мероприятия',
		},
	},
	{
		path: 'profile',
		pathMatch: 'full',
		loadChildren: () =>
			import('./modules/profile/profile.module').then(m => m.ProfileModule),
		data: {
			animation: 'profile',
			title: 'Профиль',
			right: {
				link: 'east-wagon',
				text: 'НАЗАД',
			},
			left: {
				link: 'west-wagon',
				text: 'НАЗАД',
			},
		},
		canActivate: [canActivate],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
