import {
	Directive,
	ElementRef,
	HostListener,
	OnInit,
	Renderer2,
} from '@angular/core';

@Directive({
	selector: '[appAspectRatio]',
})
export class AspectRatioDirective implements OnInit {
	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	) {}

	@HostListener('window:resize', ['$event'])
	onResize(event: Event): void {
		this.maintainAspectRatio();
	}

	ngOnInit(): void {
		this.maintainAspectRatio();
	}

	private maintainAspectRatio(): void {
		const width = window.innerWidth;
		const widthOffset = 1440 - 82 * 2;
		const newHeight = (width * 1024) / widthOffset;
		if (width < 1440) {
			this.renderer.setStyle(
				this.el.nativeElement,
				'width',
				`${width - 178}px`
			);
		} else {
			this.renderer.setStyle(
				this.el.nativeElement,
				'width',
				`${widthOffset}px`
			);
		}

		if (newHeight < 1024) {
			this.renderer.setStyle(this.el.nativeElement, 'height', `${newHeight}px`);
		} else {
			this.renderer.setStyle(this.el.nativeElement, 'height', `1024px`);
		}
	}
}
