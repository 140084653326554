import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fundTemplate } from '../../../../../assets/mock-data';

@Component({
	selector: 'app-fund-support',
	templateUrl: './fund-support.component.html',
	styleUrls: ['./fund-support.component.scss'],
})
export class FundSupportComponent {
	@Input() form: FormGroup;
	@Output() showHistory = new EventEmitter<void>();
	@Output() submitForm = new EventEmitter<void>();
	protected readonly fundTemplate = fundTemplate;
}
