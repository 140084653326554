import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state';
import { GetAllBankScore, GetPastEvents } from '../../../state/game.actions';
import { Observable } from 'rxjs';
import { selectAllBank } from '../../../../auth/state/auth.reducer';
import { NewsData } from '../../../models/game.model';
import { selectPastEvents } from '../../../state/game.reducer';

@Component({
	selector: 'app-smart-report-container',
	templateUrl: './smart-report-container.component.html',
	styleUrls: ['./smart-report-container.component.scss'],
})
export class SmartReportContainerComponent implements OnInit {
	@Input() type: 'east' | 'west';
	allBank$: Observable<number>;
	cards: string[] = ['', '', '', '', ''];
	pastEvents$: Observable<NewsData[]>;
	constructor(private store: Store<State>) {}
	ngOnInit() {
		this.store.dispatch(new GetPastEvents(this.type === 'west' ? 0 : 1));
		this.store.dispatch(new GetAllBankScore());
		this.allBank$ = this.store.select(selectAllBank);
		this.pastEvents$ = this.store.select(selectPastEvents);
	}
}
