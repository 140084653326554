<section class="hero">
	<div class="hero__container">
		<div class="hero__planet-container">
			<ng-container [ngSwitch]="activeRoute">
				<ng-container *ngSwitchCase="GameDirection.WEST">
					<img
						class="hero__planet-img"
						src="assets/images/planet-west.svg"
						alt="planet" />
					<app-west-path [percentage]="90"></app-west-path>
				</ng-container>
				<ng-container *ngSwitchCase="GameDirection.EAST">
					<app-east-path *ngSwitchCase="GameDirection.EAST"></app-east-path>
					<img
						class="hero__planet-img"
						src="assets/images/planet-east.svg"
						alt="planet" />
				</ng-container>
			</ng-container>
		</div>
		<div class="hero__city-container">
			<div
				class="hero__city"
				[ngClass]="{
					west: activeRoute === GameDirection.WEST,
					east: activeRoute === GameDirection.EAST,
					'animation-west': isEvent && activeRoute === GameDirection.WEST,
					'animation-east': isEvent && activeRoute === GameDirection.EAST
				}"></div>
		</div>
		<div class="hero__road-container">
			<div
				class="hero__road"
				[ngClass]="{
					west: activeRoute === GameDirection.WEST,
					east: activeRoute === GameDirection.EAST,
					'animation-west': isEvent && activeRoute === GameDirection.WEST,
					'animation-east': isEvent && activeRoute === GameDirection.EAST
				}"></div>
		</div>
		<app-smart-profile-avatar></app-smart-profile-avatar>
		<app-truck-container
			[animateStatus]="
				(isEvent && activeRoute === GameDirection.EAST) ||
				(isEvent && activeRoute === GameDirection.WEST)
			"
			[heartFactor]="user?.ray"
			[donates]="donateSocket"
			[direction]="activeRoute"
			(fireClick)="fireClick.emit(user?.ray)"></app-truck-container>
	</div>
	<app-smart-beam-counter></app-smart-beam-counter>
	<div class="change-left">
		<app-wagon-change-button
			type="west"
			[active]="activeRoute"></app-wagon-change-button>
	</div>
	<div class="change-right">
		<app-wagon-change-button
			type="east"
			[active]="activeRoute"></app-wagon-change-button>
	</div>
</section>
