import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../service/auth.service';
import {
	AuthActionTypes,
	ChangeAvatar,
	Donate,
	GetBankHistory,
	GetBankHistorySuccess,
	GetUser,
	GetUserSuccess,
	LogIn,
	LogInFailure,
	LogOut,
	Registration,
	ResetPassword,
	SendUserData,
	UpdateUser,
	VkAuth,
} from './auth.actions';
import {
	catchError,
	firstValueFrom,
	map,
	mergeMap,
	of,
	switchMap,
	tap,
} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { GameService } from '../../shared/service/game.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OnboardingComponent } from '../../shared/components/onboarding/onboarding.component';

@Injectable()
export class AuthEffects {
	constructor(
		private actions$: Actions,
		private service: AuthService,
		private cookie: CookieService,
		private gameService: GameService,
		private modalService: BsModalService
	) {}
	resetPassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType<ResetPassword>(AuthActionTypes.RESET_PASSWORD),
			mergeMap(action =>
				this.service.resetPassword(action.payload).pipe(
					map(() => {
						this.service.token = '';
						return new LogOut();
					})
				)
			)
		)
	);
	vkAuthentication$ = createEffect(() =>
		this.actions$.pipe(
			ofType<VkAuth>(AuthActionTypes.VK_AUTH),
			mergeMap(action =>
				this.service.loginUserOverVk(action.payload).pipe(
					map(data => {
						this.service.token = data;
						if (typeof data === 'string') {
							this.modalService.hide();
						}
						return new GetUser();
					})
				)
			)
		)
	);
	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LogIn | Registration>(
				AuthActionTypes.LOG_IN,
				AuthActionTypes.REGISTRATION
			),
			mergeMap(action =>
				this.service.authentication(action.payload, action.type).pipe(
					map(data => {
						if (!data) {
							return new LogInFailure();
						}
						this.service.token = data;
						return new GetUser();
					})
				)
			)
		)
	);

	donate$ = createEffect(() =>
		this.actions$.pipe(
			ofType<Donate>(AuthActionTypes.DONATE),
			switchMap(action =>
				this.service.donate(action.payload).pipe(map(() => new UpdateUser()))
			)
		)
	);

	logout$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<LogOut>(AuthActionTypes.LOG_OUT),
				tap(action => {
					this.gameService.closeConnection();
					this.service.clearToken();
				})
			),
		{
			dispatch: false,
		}
	);

	sendAddress$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<SendUserData>(AuthActionTypes.SEND_ADDRESS),
				tap(async action => {
					const result = await firstValueFrom(
						this.service.sendAddress(action.payload)
					);
					return result;
				})
			),
		{
			dispatch: false,
		}
	);

	changeAvatar$ = createEffect(() =>
		this.actions$.pipe(
			ofType<ChangeAvatar>(AuthActionTypes.CHANGE_AVATAR),
			mergeMap(action =>
				this.service
					.changeAvatar(action.payload)
					.pipe(map(() => new UpdateUser()))
			)
		)
	);

	bankHistory$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetBankHistory>(AuthActionTypes.GET_BANK_HISTORY),
			mergeMap(action =>
				this.service
					.getBankHistory()
					.pipe(map(data => new GetBankHistorySuccess(data)))
			)
		)
	);

	getUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType<GetUser>(AuthActionTypes.GET_USER),
			switchMap(() =>
				this.service.getUser().pipe(
					map(data => {
						this.gameService.openConnection(data);
						return new GetUserSuccess(data);
					}),
					catchError(err => {
						if (this.gameService.onBoarding !== 'true') {
							this.modalService.show(OnboardingComponent, {
								class: 'transparent modal-xl',
								ignoreBackdropClick: true,
								animated: true,
							});
						}
						this.gameService.openConnection();

						return of(new LogInFailure());
					})
				)
			)
		)
	);

	updateUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType<UpdateUser>(AuthActionTypes.UPDATE_USER),
			switchMap(() =>
				this.service.getUser().pipe(
					map(data => {
						return new GetUserSuccess(data);
					})
				)
			)
		)
	);
}
