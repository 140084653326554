import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state';
import { selectBankHistory } from '../../../../auth/state/auth.reducer';
import { PaymentItem } from '../../../models/profile.model';

@Component({
	selector: 'app-smart-operation-history',
	template: `
		<app-operation-history
			[bankHistory]="bankHistory$ | async"></app-operation-history>
	`,
})
export class SmartOperationHistoryComponent implements OnInit {
	bankHistory$: Observable<PaymentItem[]>;
	constructor(private store: Store<State>) {}

	ngOnInit() {
		this.bankHistory$ = this.store.select(selectBankHistory);
	}
}
