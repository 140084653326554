<div class="article" *ngIf="article">
	<div class="article__head">
		<img
			class="article__head-logo"
			src="../../../../../assets/images/article-head-logo.png"
			alt="logo" />
		<img class="article__head-image" [src]="article.pic" alt="image" />
		<div class="article__head-title">{{ article.h1 }}</div>
	</div>
	<div class="article__wrapper" [innerHtml]="article.bodyText"> </div>

	<div class="article__slider" *ngIf="article.slider?.length > 0">
		<swiper-container
			init="false"
			appSwiperSlider
			[setActiveSlide]="activeSlide$ | async"
			[config]="config">
			<swiper-slide
				class="article__slider-item"
				*ngFor="let slide of article.sliderImages; let i = index"
				(click)="onShow(600, i)">
				<img class="article__slider-item-image" [src]="slide" alt="image" />
			</swiper-slide>
		</swiper-container>
	</div>

	<div class="article__wrapper" [innerHtml]="article.bodyText2"> </div>
	<app-foto-gallery
		*ngIf="showArticleImages && articleImages?.original?.length"
		[images]="articleImages.original"
		[gallery]="articleImages.gallery"></app-foto-gallery>
</div>
