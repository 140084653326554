import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { CitiesNames } from '../../../../../assets/cities.data';

@Component({
	selector: 'app-west-path',
	templateUrl: './west-path.component.html',
	styleUrls: [
		'./west-path.component.scss',
		'../hero-container/hero-container.component.scss',
	],
	animations: [
		trigger('dashAnimate', [
			state(
				'initial',
				style({
					strokeDashoffset: '{{length}}',
				}),
				{ params: { length: 0 } }
			),
			transition('* => *', [animate('10s')]),
		]),
	],
})
export class WestPathComponent implements OnChanges {
	@Input() percentage = 0;
	dashoffset: number;

	protected readonly CitiesNames = CitiesNames;
	@ViewChild('tooltip') tooltip: ElementRef;
	@ViewChild('parent') parent: ElementRef;

	constructor(private renderer: Renderer2) {}

	ngOnChanges(changes: SimpleChanges): void {
		if ('percentage' in changes) {
			const svgElement: SVGPathElement = document.querySelector('#path2');
			const length = svgElement?.getTotalLength();
			this.dashoffset = length - length * (this.percentage / 100);
		}
	}

	public mouseEnter($event, city: CitiesNames, date: string): void {
		const parentRect = this.parent.nativeElement.getBoundingClientRect();
		const childRect = ($event.target as HTMLElement).getBoundingClientRect();

		const relativeX = childRect.left - parentRect.left;
		const relativeY = childRect.top - parentRect.top;
		const x = `${relativeX + 5}px`;
		const y = `${relativeY - 30}px`;
		this.renderer.setStyle(this.tooltip.nativeElement, 'left', x);
		this.renderer.setStyle(this.tooltip.nativeElement, 'top', y);
		this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'block');
		this.renderer.setProperty(
			this.tooltip.nativeElement,
			'innerHTML',
			`${city}<br>${date}`
		);
	}

	public mouseLeave(): void {
		this.renderer.setProperty(this.tooltip.nativeElement, 'innerHTML', '');
		this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'none');
	}
}
