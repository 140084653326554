import { Component } from '@angular/core';

export interface CityDescriptionData {
	name: string;
	stadium: string;
	date: string;
	time: string;
}

@Component({
	selector: 'app-card-description',
	templateUrl: './card-city-description.component.html',
	styleUrls: ['./card-city-description.component.scss'],
})
export class CardCityDescriptionComponent {}
