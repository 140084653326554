<div class="beam-counter">
	<div class="beam-counter__top"  *ngIf="socket?.allScore">
		<div class="beam-counter__name">Заряд добра</div>
		<div class="beam-counter__counter">{{
			socket.allScore | transformNumber
		}}</div>
	</div>
	<div class="beam-counter__bottom">
		<img class="beam-counter__sun" src="assets/images/sun.svg" alt="sun" />
		<div class="beam-counter__progress">
			<div
				[@nextRaffle]="width"
				[style.width]="width + '%'"
				class="beam-counter__progress-inner"></div>
		</div>
		<img class="beam-counter__gift" src="assets/images/gift.svg" alt="gift" />
	</div>
</div>
