import { Component, Input } from '@angular/core';
import { FundData } from '../../models/game.model';

@Component({
	selector: 'app-card-fund',
	template: `<div class="card-fund" *ngIf="fund">
		<div class="card-fund__top">
			<img
				class="card-fund__image"
				loading="lazy"
				[src]="fund.pic"
				alt="bear" />
			<div class="card-fund__title">{{ fund.title }}</div>
			<div class="card-fund__text" [innerHTML]="fund.description"></div>
		</div>
		<div class="card-fund__bottom">
			<div class="card-fund__info">
				<div class="card-fund__info-text">Поддержка от</div>
				<div class="card-fund__info-title">{{ fund.supportFrom }}</div>
				<div class="card-fund__info-location">
					<img src="assets/images/location.svg" alt="location" />
					{{ fund.city }}
				</div>
			</div>
			<div class="card-fund__globus">
				<a [href]="fund.linkUrl" target="_blank"
					><img src="assets/images/globus.svg" alt="globus"
				/></a>
			</div>
		</div>
	</div>`,
	styleUrls: ['./card-fund.component.scss'],
})
export class CardFundComponent {
	@Input() fund: FundData;
}
